import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  Tooltip
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import {
  fetchAllGeographies,
  getGeographies,
  getStatusGeographies,
} from "../../../parts/geographies/geographiesSlice";
import {
  GetAlliesSuppliers,
  GetAlliesTraders,
} from "../../../services/user/deleteAndReload/deleteAndReload";
import { CODES } from "../../../consts/codes";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export const AlliesFilterForm = ({
  handleSubmit,
  control,
  errors,
  setValue,
  reset,
  watch,
  setOpenModalDecision,
  setAlliesReloadForm,
  allySelected,
}) => {
  /**
   * Const
   */

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * useMediaQuery for HD responsive
   */
  const matches = useMediaQuery("(min-width:1670px)");

  /**
   * Use Selector
   */

  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  /**
   * Use State
   */

  //Listas de autocomplete
  const [listCountries, setListCountries] = useState([]);
  const [listCommerces, setListCommerces] = useState([]);
  const [listSuppliers, setListSuppliers] = useState([]);

  //Boolean de país seleccionado
  const [isCountrySelected, setIsCountrySelected] = useState(false);

  //Data cargando
  const [isDataLoading, setIsDataLoading] = useState(false);

  /**
   * Const
   */

  //Lista de opciones para tipo de documento
  const listTypeDocument = [
    { label: t("App.downloadSalesForce.selectAll") },
    { label: t("App.allies.stock"), value: "stock" },
    { label: t("App.deleteAndReload.filters.typeSales"), value: "sales" },
    { label: t("App.externalFiles.select.salesStock"), value: "sales_stock" },
  ];

  //Lista de opciones para tipo de documento
  const listTypeReload = [
    { label: t("App.allies.filter.historic"), value: "historical" },
    { label: t("App.allies.filter.reprocessing"), value: "reprocess" },
  ];

  /**
   * Use Effect
   */

  /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        if (countriesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }
      } catch (error) {
        console.log(
          "============== Error alliesFilterForm.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
    setListCountries(countries);
  }, [dispatch, countriesStatus]);

  /**
   * Handles
   */

  /**
   * Consulta los comercios de un país
   * @param {Object} country país a consultar
   * @returns lista de comercios por país consultado
   */
  const fetchCommerces = async (country) => {
    try {
      const obj = {
        country: country.country,
        idAllied: allySelected,
      };

      const tradersService = await GetAlliesTraders(obj);

      const responseCode = tradersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = tradersService.data.responseMessage;

          let tradersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          const completeList =
            responseMessage.length > 0
              ? [
                  { label: t("App.downloadSalesForce.selectAll") },
                  ...tradersList,
                ]
              : [];
          return completeList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================= Error alliesFilterForm.jsx fetchCommerces ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Consulta los proveedores de un país específico
   * @param {*} country país a consultar
   * @returns lista de proveedores por país consultado
   */
  const fetchSuppliers = async (country) => {
    try {
      const obj = {
        country: country.country,
        idAllied: allySelected,
      };

      const suppliersService = await GetAlliesSuppliers(obj);
      const responseCode = suppliersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          const completeList =
            responseMessage.length > 0
              ? [
                  { label: t("App.downloadSalesForce.selectAll") },
                  ...suppliersList,
                ]
              : [];

          return completeList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================= Error alliesFilterForm.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Maneja los cambios en el formulario basado en el valor del campo país
   * @param {*} values valor del campo país
   */
  const handleCountryChange = async (values) => {
    try {
      if (values !== null) {
        //Si se selecciona un país
        setIsCountrySelected(true);
        setIsDataLoading(true);

        const newCommerces = await fetchCommerces(values);
        const newSuppliers = await fetchSuppliers(values);

        setListCommerces(newCommerces);
        setListSuppliers(newSuppliers);
        setIsDataLoading(false);
      } else {
        //Si el campo se borra o se hace clean
        setIsCountrySelected(false);
        setListCommerces([]);
        setValue("commerce", []);
        setListSuppliers([]);
        setValue("supplier", []);
      }
    } catch (error) {
      console.log(
        "================= Error alliesFilterForm.jsx handleCountryChange ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Maneja los casos de Seleccionar todos para los campos commerce/supplier/documentType
   * @param {array} list lista de elementos seleccionados
   * @param {*} reason tipo de acción: selectOption, removeOption
   * @param {*} detail opción actualmente seleccionada
   * @param {*} type tipo de campo: commerce/supplier/documentType
   * @returns true si el caso contiene selectAll, false si no posee el caso
   */
  const handleSelectAllSelection = (list, reason, detail, type) => {
    let includeSelectAll = false;

    //Reviso si la opción cliqueada es selectAll y fue deseleccionada
    if (detail) {
      const option = detail.option;
      const element = option.label;

      if (
        reason === "removeOption" &&
        element === t("App.downloadSalesForce.selectAll")
      ) {
        setValue(type, []);

        return true;
      }
    }

    //Reviso si el select all fue seleccionado
    for (const element of list) {
      if (element.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    const originalList =
      type === "commerce"
        ? listCommerces
        : type === "supplier"
        ? listSuppliers
        : listTypeDocument;

    //Reviso si la opción select all fue seleccionada o
    //la lista de opciones seleccionadas fueron todas
    if (
      (includeSelectAll && reason === "selectOption") ||
      (!includeSelectAll && list.length === originalList.length - 1)
    ) {
      switch (type) {
        case "commerce":
          setValue(type, listCommerces);

          break;
        case "supplier":
          setValue(type, listSuppliers);

          break;
        case "documentType":
          setValue(type, listTypeDocument);
          break;
        default:
          break;
      }

      return true;
    }

    //Si la opción no fue seleccionada retorno falso
    if (!includeSelectAll) {
      return false;
    }

    //Si la opción select All se encuentra seleccionada y se deseleccionó otra opción
    if (includeSelectAll && reason === "removeOption") {
      const withoutSelectAll = list.filter(
        (element) => element.label !== t("App.downloadSalesForce.selectAll")
      );

      setValue(type, withoutSelectAll);

      return true;
    }
  };

  /**
   * Maneja los cambios sobre el campo Comercio
   * @param {*} values lista de comercios seleccionados
   * @param {*} reason tipo de acción: selectOption, removeOption
   * @param {*} detail opción seleccionada
   */
  const handleCommerceChange = (values, reason, detail) => {
    handleSelectAllSelection(values, reason, detail, "commerce");
  };

  /**
   * Maneja los cambios sobre el campo Proveedor
   * @param {*} values lista de proveedores seleccionados
   * @param {*} reason tipo de acción: selectOption, removeOption
   * @param {*} detail opción seleccionada
   */
  const handleSupplierChange = (values, reason, detail) => {
    handleSelectAllSelection(values, reason, detail, "supplier");
  };

  /**
   * Maneja los cambios sobre el campo tipo de documento
   * @param {*} values lista de tipo de documentos seleccionados
   * @param {*} reason tipo de acción: selectOption, removeOption
   * @param {*} detail opción seleccionada
   */
  const handleDocumentTypeChange = (values, reason, detail) => {
    handleSelectAllSelection(values, reason, detail, "documentType");
  };

  /**
   * Deshabilita el botón de aplicar recarga basado en el estado de los campos del formulario
   * @returns true si el botón se deshabilita, false si no se deshabilita
   */
  const disableReloadButton = () => {
    const currentFields = watch();

    if (
      currentFields.country !== "" &&
      currentFields.commerce.length > 0 &&
      currentFields.supplier.length > 0 &&
      currentFields.documentType.length > 0 &&
      (currentFields.finalDate !== null) & (currentFields.initialDate !== null)
    ) {
      return false;
    }
    return true;
  };

  /**
   * Resetea los campos del formulario
   */
  const cleanFilters = () => {
    reset();
  };

  /**
   * Recibe y almacena los datos seleccionados en el filtro
   * Abre el modal de decisión de recarga
   * @param {*} data filtros seleccionados.
   */
  const handleApplyReload = (data) => {
    setAlliesReloadForm(data);
    setOpenModalDecision(true);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      columnSpacing={4}
      className="field_container__block "
    >
      <Grid
        item
        className="side-line__bottomAlign"
        md={9}
        sm={7}
        xs={8}
        style={{
          padding: "0% 0% 0% 0%",
          display: "flex",
          justifyContent: "center",
          maxWidth: "100%",
        }}
      >
        <form id="reload-allies" onSubmit={handleSubmit(handleApplyReload)}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="left"
            rowSpacing={2}
            className="filter_container"
          >
            <Grid item className="field_container__block">
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Grid container columnSpacing={4} direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.listUsers.countryN")}*{" "}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        name={"country"}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={listCountries}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) => {
                              return option.country == value.country;
                            }}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              matches
                                ? errors.country
                                  ? "select__filters__errors__medium"
                                  : "select__filters__medium"
                                : errors.country
                                ? "select__filters__errors__xs"
                                : "select__filters__xs"
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t("App.listUsers.countryN")}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleCountryChange(values, reason);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                    {errors && errors.country && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.country.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.allies.filter.commerce")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        defaultValue={[]}
                        name={`commerce`}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            disabled={
                              isCountrySelected
                                ? isDataLoading
                                  ? true
                                  : false
                                : true
                            }
                            className={
                              matches
                                ? errors.commerce
                                  ? "select__filters__errors__medium"
                                  : "select__filters__medium"
                                : errors.commerce
                                ? "select__filters__errors__xs"
                                : "select__filters__xs"
                            }
                            disableCloseOnSelect
                            id="checkboxes-tags-commerce"
                            options={listCommerces}
                            multiple
                            limitTags={2}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            getOptionLabel={(option) => option.label}
                            value={value}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.value}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            )}
                            onChange={(event, values, reason, detail) => {
                              onChange(values, reason);
                              handleCommerceChange(values, reason, detail);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t("App.allies.filter.commerce")}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isDataLoading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              );
                            }}
                            renderTags={(value, getTagProps) => {
                              const limitTags = 1;
                              const withoutSelectAll = value.filter(
                                (item) =>
                                  item.label !==
                                  t("App.downloadSalesForce.selectAll")
                              );
                              const numTags = withoutSelectAll.length;
                              return (
                                <>
                                  {withoutSelectAll
                                    .slice(0, limitTags)
                                    .map((option, index) => (
                                      <Chip
                                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                        sx={{
                                          width: "40%",
                                        }}
                                        key={index}
                                        size="small"
                                        label={`${option.label}`}
                                      />
                                    ))}
                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {errors && errors.commerce && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.commerce.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.deleteAndReload.filters.supplier")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        defaultValue={[]}
                        name={`supplier`}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            disabled={
                              isCountrySelected
                                ? isDataLoading
                                  ? true
                                  : false
                                : true
                            }
                            className={
                              matches
                                ? errors.supplier
                                  ? "select__filters__errors__medium"
                                  : "select__filters__medium"
                                : errors.supplier
                                ? "select__filters__errors__xs"
                                : "select__filters__xs"
                            }
                            disableCloseOnSelect
                            id="checkboxes-tags-commerce"
                            options={listSuppliers}
                            multiple
                            limitTags={2}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            getOptionLabel={(option) => option.label}
                            value={value}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.value}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            )}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              handleSupplierChange(values, reason, detail);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.deleteAndReload.filters.supplier"
                                  )}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isDataLoading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              );
                            }}
                            renderTags={(value, getTagProps) => {
                              const limitTags = 1;
                              const withoutSelectAll = value.filter(
                                (item) =>
                                  item.label !==
                                  t("App.downloadSalesForce.selectAll")
                              );
                              const numTags = withoutSelectAll.length;
                              return (
                                <>
                                  {withoutSelectAll
                                    .slice(0, limitTags)
                                    .map((option, index) => (
                                      <Chip
                                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                        sx={{
                                          width: "40%",
                                        }}
                                        key={index}
                                        size="small"
                                        label={`${option.label}`}
                                      />
                                    ))}
                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {errors && errors.supplier && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.supplier.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.deleteAndReload.filters.documentType")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        defaultValue={[]}
                        name={`documentType`}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              matches
                                ? errors.documentType
                                  ? "select__filters__errors__medium"
                                  : "select__filters__medium"
                                : errors.documentType
                                ? "select__filters__errors__xs"
                                : "select__filters__xs"
                            }
                            disableCloseOnSelect
                            defaultValue={[]}
                            id="checkboxes-tags-commerce"
                            options={listTypeDocument}
                            multiple
                            limitTags={2}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            getOptionLabel={(option) => option.label}
                            value={value || []}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.value}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            )}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              handleDocumentTypeChange(values, reason, detail);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.deleteAndReload.filters.documentType"
                                  )}
                                  {...params}
                                />
                              );
                            }}
                            renderTags={(value, getTagProps) => {
                              const limitTags = 1;
                              const withoutSelectAll = value.filter(
                                (item) =>
                                  item.label !==
                                  t("App.downloadSalesForce.selectAll")
                              );
                              const numTags = withoutSelectAll.length;
                              return (
                                <>
                                  {withoutSelectAll
                                    .slice(0, limitTags)
                                    .map((option, index) => (
                                      <Chip
                                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                        sx={{
                                          width: "40%",
                                        }}
                                        key={index}
                                        size="small"
                                        label={`${option.label}`}
                                      />
                                    ))}
                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {errors && errors.documentType && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.documentType.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.allies.filter.typeReloadO")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name={`rechargeType`}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Controller
                          name={"rechargeType"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              options={listTypeReload}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => {
                                return option.value == value.value;
                              }}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                matches
                                  ? errors.rechargeType
                                    ? "select__filters__errors__medium"
                                    : "select__filters__medium"
                                  : errors.rechargeType
                                  ? "select__filters__errors__xs"
                                  : "select__filters__xs"
                              }
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.allies.filter.typeReloadO")}
                                    {...params}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                        )}
                      />
                    </Grid>
                    {errors && errors.documentType && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.documentType.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.deleteAndReload.filters.initialDateReported")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        defaultValue={null}
                        control={control}
                        name="initialDate"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          formState,
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat="YYYY-MM-DD"
                              className={
                                matches
                                  ? errors.initialDate
                                    ? "select__filters__errors__medium"
                                    : "select__filters__medium"
                                  : errors.initialDate
                                  ? "select__filters__errors__xs"
                                  : "select__filters__xs"
                              }
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              inputProps={{
                                placeholder: t(
                                  "App.deleteAndReload.filters.initialDateReported"
                                ),
                              }}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  size="medium"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    {errors && errors.initialDate && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.initialDate.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.allies.filter.finalDate")}
                      </label>
                    </Grid>
                    <Grid item>
                      {" "}
                      <Controller
                        defaultValue={null}
                        control={control}
                        name="finalDate"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          formState,
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat="YYYY-MM-DD"
                              className={
                                matches
                                  ? errors.finalDate
                                    ? "select__filters__errors__medium"
                                    : "select__filters__medium"
                                  : errors.finalDate
                                  ? "select__filters__errors__xs"
                                  : "select__filters__xs"
                              }
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              inputProps={{
                                placeholder: t("App.allies.filter.finalDate"),
                              }}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  size="medium"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    {errors && errors.finalDate && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.finalDate.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item className="btn__group">
        <Grid
          container
          direction="row"
          columnSpacing={2}
          className="btn__group"
        >
          <Grid item md={9} sm={8} xs={12} className="btn__container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* Ícono de información a la izquierda */}
              <Tooltip title={t("App.allies.filter.tooltip")} placement="right">
                <InfoIcon style={{ color: "#553AB6", marginRight: '8px' }} />
              </Tooltip>

              {/* Botón a la derecha del ícono */}
              <Button
                type="submit"
                form="reload-allies"
                disabled={disableReloadButton()}
                className={
                  disableReloadButton()
                    ? "btn__applyFilter-disabled"
                    : "btn__applyFilter"
                }
                startIcon={<TuneOutlinedIcon />}
              >
                {t("App.deleteAndReload.reloadChildren.applyReload")}
              </Button>
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs={12} className="btn__container">
            <Button onClick={cleanFilters} className="btn__deleteFilter">
              {" "}
              {t("App.buttonLabels.clean")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
