import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import RightArrow from "@mui/icons-material/KeyboardArrowRight";
import LeftArrow from "@mui/icons-material/KeyboardArrowLeft";
import PropTypes from "prop-types";
import {useMediaQuery} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState, useReducer } from "react";
import { is } from "date-fns/locale";

const FieldsAccordionEdit = ({
  control,
  errors,
  t,
  getValues,
  setValue,
  isStoreDetailSelected,
  isCheckEdited,
  editedItem,
}) => {
  /**
   * Const
   */

  // Use State
  const [fieldsListInit, setFieldsListInit] = useState([]);
  // Use State para manejar la paginación en la vista mobile
  const [currentPage, setCurrentPage] = useState(0);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  //Tipos de formato
  const alphanumericType = {
    label: t("App.loadMapping.createModal.format.alphanumeric"),
    value: "alphanumeric",
  };
  const numericType = {
    label: t("App.loadMapping.createModal.format.numeric"),
    value: "numeric",
  };

  // Lista de fields dependiendo del tipo de documento

  //Lista de fields para el mapeo de ventas
  const salesFieldsList = [
    {
      label: t("App.loadMapping.createModal.formFields.pointOfSale"),
      value: "ean_point_sale",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.product"),
      value: "ean_product",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.reportDate"),
      value: "report_date",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.salesQuantity"),
      value: "sales_quantity",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.salesPrice"),
      value: "sales_price_net",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("sales_price_net")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.internalCode"),
      value: "internal_code",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("internal_code")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.salesPriceList"),
      value: "sales_price_list",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("sales_price_list")
        ? true
        : false,
    },
  ];

  //Lista de fields para el mapeo de stock
  const stockFieldsList = [
    {
      label: t("App.loadMapping.createModal.formFields.pointOfSale"),
      value: "ean_point_sale",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.product"),
      value: "ean_product",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.reportDate"),
      value: "report_date",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.stockQuantity"),
      value: "stock_quantity",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.stockPrice"),
      value: "stock_price_net",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("stock_price_net")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.internalCode"),
      value: "internal_code",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("internal_code")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.transitQuantity"),
      value: "qty_on_transit",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("qty_on_transit")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.stockPriceList"),
      value: "stock_price_list",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("stock_price_list")
        ? true
        : false,
    },
  ];

  //Lista de fields para el mapeo de salesstock
  const salesStockFieldsList = [
    {
      label: t("App.loadMapping.createModal.formFields.pointOfSale"),
      value: "ean_point_sale",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.product"),
      value: "ean_product",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.reportDate"),
      value: "report_date",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.salesQuantity"),
      value: "sales_quantity",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.stockQuantity"),
      value: "stock_quantity",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.formFields.salesPrice"),
      value: "sales_price_net",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("sales_price_net")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.stockPrice"),
      value: "stock_price_net",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("stock_price_net")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.internalCode"),
      value: "internal_code",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("internal_code")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.transitQuantity"),
      value: "qty_on_transit",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("qty_on_transit")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.salesPriceList"),
      value: "sales_price_list",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("sales_price_list")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.formFields.stockPriceList"),
      value: "stock_price_list",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("stock_price_list")
        ? true
        : false,
    },
  ];

  //Lista de fields para el mapeo de detalle tienda
  const storeDetailsFields = [
    {
      label: t("App.loadMapping.createModal.storeDetail.pointOfSale"),
      value: "ean_point_sale",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.product"),
      value: "ean_product",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.salesDate"),
      value: "report_date",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.salesQuantity"),
      value: "sales_quantity",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.salesPrice"),
      value: "sales_price_net",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.storeIdCode"),
      value: "store_id_code",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.storeName"),
      value: "store_name",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.sellerCode"),
      value: "seller_code",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.sellerName"),
      value: "seller_name",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.cityCode"),
      value: "city_code",
      selected: true,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.storeNameFormat"),
      value: "store_format_name",
      selected: false,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.totalNetValue"),
      value: "total_net_value",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("total_net_value")
        ? true
        : false,
    },
    {
      label: t("App.loadMapping.createModal.storeDetail.postalCode"),
      value: "postal_code",
      selected: editedItem.fields
        .map((field) => field.field)
        .includes("postal_code")
        ? true
        : false,
    },
  ];

  // Funcion para escoger una lista de fields dependiendo del documento
  const getFieldsList = (editedItem) => {
    switch (editedItem.typeDocument) {
      case "sales":
        if (isStoreDetailSelected === true) {
          return storeDetailsFields;
        } else {
          return salesFieldsList;
        }
      case "stock":
        return stockFieldsList;
      case "sales_stock":
        return salesStockFieldsList;
      default:
        return salesFieldsList;
    }
  };
  //Lista de fields
  const initialFieldsList = getFieldsList(editedItem);

  // Lista de posiciones
  let initialPositionList = [];
  let i = 0;
  const upperLimit = 30;

  while (i < upperLimit) {
    initialPositionList.push({
      label: t("App.loadMapping.createModal.position.column") + ` ${i + 1}`,
      value: `${i + 1}`,
    });
    i = i + 1;
  }

  //Mapa de formatos
  const formatMap = new Map();

  formatMap.set("ean_point_sale", [alphanumericType]);
  formatMap.set("ean_product", [alphanumericType]);
  formatMap.set("report_date", [
    { label: t("App.loadMapping.createModal.format.date1"), value: "AAAAMMDD" },
    { label: t("App.loadMapping.createModal.format.date2"), value: "DDMMAAAA" },
    {
      label: t("App.loadMapping.createModal.format.date3"),
      value: "AAAA + Semana ISO",
    },
  ]);
  formatMap.set("sales_quantity", [numericType]);
  formatMap.set("stock_quantity", [numericType]);
  formatMap.set("sales_price_net", [numericType]);
  formatMap.set("stock_price_net", [numericType]);
  formatMap.set("internal_code", [alphanumericType]);
  formatMap.set("qty_on_transit", [numericType]);
  formatMap.set("sales_price_list", [numericType]);
  formatMap.set("stock_price_list", [numericType]);
  //Campos del detalle a tienda
  formatMap.set("store_id_code", [alphanumericType]);
  formatMap.set("store_name", [alphanumericType]);
  formatMap.set("seller_code", [alphanumericType]);
  formatMap.set("seller_name", [alphanumericType]);
  formatMap.set("city_code", [alphanumericType]);
  formatMap.set("store_format_name", [alphanumericType]);
  formatMap.set("total_net_value", [numericType]);
  formatMap.set("postal_code", [alphanumericType]);

  //Tamaño de arreglo de fields
  const fieldsLength = fieldsListInit.length;

  /**
   * Use Reducer
   */

  //Reducer de campos fields
  const initialStateFields = () => initialFieldsList;
  const fieldsReducer = (
    state = initialStateFields(),
    action = { field: "", type: "", typeOfAction: "", newArray: [] }
  ) => {
    switch (action.typeOfAction) {
      case "updateArray":
        const newState = state.map((currentField) => {
          if (currentField.value === action.field) {
            if (action.type === "select") {
              return { ...currentField, selected: true };
            } else {
              return { ...currentField, selected: false };
            }
          } else {
            return currentField;
          }
        });
        return newState;
        break;
      case "setArray":
        return action.newArray;
        break;
      default:
        return state;
    }
  };

  const [fieldsList, dispatchFields] = useReducer(
    fieldsReducer,
    fieldsReducer()
  );

  //Reducer de campos posicion
  const initialStatePosition = () => initialPositionList;

  const positionReducer = (
    state = initialStatePosition(),
    action = { position: "", type: "" }
  ) => {
    const newState = state.map((currentPosition) => {
      if (currentPosition.value === action.position) {
        if (action.type === "select") {
          return { ...currentPosition, selected: true };
        } else {
          return { ...currentPosition, selected: false };
        }
      } else {
        return currentPosition;
      }
    });
    return newState;
  };

  const [positionList, dispatchPosition] = useReducer(
    positionReducer,
    positionReducer()
  );

  /**
   * Use Array Field
   */
  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappingFields",
  });

  /**
   * Función para manejar la cantidad de campos que no se pueden borrar
   */
  const finalIndexCount = () => {
    if (editedItem) {
      if (editedItem.typeDocument == "sales" && isCheckEdited == true) {
        resetPositionArray();
        if (isStoreDetailSelected == true) {
          return 10;
        } else {
          return 4;
        }
      } else if (
        (editedItem.typeDocument == "stock" ||
          editedItem.typeDocument == "sales") &&
        editedItem.storeDetail == "NO" &&
        isCheckEdited == false
      ) {
        return 4;
      } else if (
        editedItem.typeDocument == "sales_stock" &&
        isCheckEdited == false
      ) {
        return 5;
      } else {
        return 10;
      }
    }
  };
  /**
   * Función para manejar el tamaño de la pantalla
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * Use Effect
   */

  useEffect(() => {
    setCurrentPage(0);
    setValue("mappingFields", []);
    const fieldsOfMap = editedItem.fields;
    let i = 0;
    setFieldsListInit(getFieldsList(editedItem));
    for (const field of fieldsOfMap) {
      append({
        ...field,
        key: uuidv4(),
        disabled: i < finalIndexCount() ? true : false,
      });
      dispatchFields({
        type: "select",
        field: field.field,
        typeOfAction: "setArray",
        newArray: getFieldsList(editedItem),
      });
      if (isCheckEdited === true) {
        updateFields();
      }
      dispatchPosition({ type: "select", position: field.position });
      i = i + 1;
    }
  }, [isStoreDetailSelected]);

  /**
   * Use Effect para manejar el tamaño de la pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Handles
   */

  /**
   * Función que maneja los cambios del campo Campo cuando este es seleccionado
   * @param {*} currentValue valor de campo seleccionado
   * @param {*} index Índice en el arreglo de campos
   */
  const handleChangeFieldType = (currentValue, index) => {
    //Verificamos si existía valor previo
    const previousValue = getValues(`mappingFields.${index}.field`);
    if (previousValue) {
      //Si hay valor previo lo marcamos como deseleccionado
      dispatchFields({
        type: "deselect",
        field: previousValue,
        typeOfAction: "updateArray",
      });
    }
    //Seleccionamos el currentValue en la lista de campos
    dispatchFields({
      type: "select",
      field: currentValue,
      typeOfAction: "updateArray",
    });
    setValue(`mappingFields.${index}.format`, "");
  };

  /**
   * Función que maneja los cambios al seleccionar el campo Posición
   * @param {*} currentValue valor de posición seleccionado
   * @param {*} index índice en el arreglo de campos
   */
  const handleChangePosition = (currentValue, index) => {
    //Verificamos si hay valor previo
    const previousValue = getValues(`mappingFields.${index}.position`);
    if (previousValue) {
      //Si hay valor previo lo marcamos como deseleccionado
      dispatchPosition({ type: "deselect", position: previousValue });
    }
    //Marcamos seleccionado el valor elegido por el usuario
    dispatchPosition({ type: "select", position: currentValue });
  };

  /**
   * Función que añade una nueva fila de campos al formulario
   */
  const handleAddField = () => {
    const emptyField = {
      field: "",
      position: "",
      format: "",
      disabled: false,
      key: uuidv4(),
    };
    append(emptyField);
    setCurrentPage(fields.length);
  };

  /**
   * Función que maneja las acciones a ejecutar cuando una fila de campos es eliminada
   * @param {*} index índice de la fila a eliminar
   */
  const handleRemoveField = (index) => {
    //Tomamos los valores actuales en la fila
    const currentField = getValues(`mappingFields.${index}`);

    if (currentField.field) {
      //Si existe valor de field, lo marcamos deseleccionado
      dispatchFields({
        type: "deselect",
        field: currentField.field,
        typeOfAction: "updateArray",
      });
    }

    if (currentField.position) {
      // Si existe valor de position, lo marcamos deseleccionado
      dispatchPosition({ type: "deselect", position: currentField.position });
    }

    //Eliminamos la fila del arreglo de campos
    remove(index);
  };

  /**
   * Función que retorna la lista de formatos según el campo seleccionado
   * @param {*} index índice de la fila de campos
   * @returns lista de formatos
   */
  const getFormatByFieldId = (index) => {
    const currentField = getValues(`mappingFields.${index}.field`);
    if (currentField) {
      return formatMap.get(currentField);
    } else {
      return [];
    }
  };

  /**
   * Función que actualiza la cantidad de campos al cambiar entre detalle de tienda y ventas
   */

  const updateFields = () => {
    setValue("mappingFields", []);
    let initialIndex = 0;
    const finalIndex = getFinalIndex();
    while (initialIndex < finalIndex) {
      const currentField = {
        field: initialFieldsList[initialIndex].value,
        position: "",
        format: "",
        key: uuidv4(),
        disabled: true,
      };
      append(currentField);
      initialIndex = initialIndex + 1;
    }
  };

  /**
   * Función que devuelve el final index para cargar campos
   */

  const getFinalIndex = () => {
    if (editedItem.typeDocument == "sales" && isStoreDetailSelected == true) {
      return 10;
    } else if (
      editedItem.typeDocument == "sales" &&
      isStoreDetailSelected == false
    ) {
      return 4;
    }
  };

  /**
   * Función que reinicia el array de positions
   */

  const resetPositionArray = () => {
    let i = 0;
    while (i < upperLimit) {
      dispatchPosition({ type: "deselect", position: `${i + 1}` });
      i = i + 1;
    }
  };

  /**
   * Función para manejar la cantidad de campos que no se pueden borrar
   */
  const disabledFieldsIndex = () => {
    if (editedItem.typeDocument == "sales" && isStoreDetailSelected == true) {
      return 9;
    } else if (
      editedItem.typeDocument == "sales_stock" &&
      isStoreDetailSelected == false
    ) {
      return 4;
    } else {
      return 3;
    }
  };

  /**
   * Funciones para el manejo de la paginación en la vista mobile
   */

  /**
   * Función que maneja el cambio a la siguiente página de campos en la vista mobile
   */
  const nextPage = () => {
    if (currentPage !== fields.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  /**
   * Función que maneja la visibilidad del botón de página siguiente en la vista mobile
   */
  const showNextPageButton = () => {
    if (currentPage === fields.length - 1) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Función que maneja el cambio a la página anterior de campos en la vista mobile
   */
  const previousPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  /**
   * Función que maneja la visibilidad del botón de página anterior en la vista mobile
   */
  const showPreviousPageButton = () => {
    if (currentPage === 0) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Función que habilita el botón de añadir campo mobile
   */
  const handleAddFieldMobile = () => {
    if (fields.length < fieldsLength) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Función que habilita el botón de remover campo mobile
   */
  const handleRemoveFieldMobile = () => {
    let limit = disabledFieldsIndex();
    if (currentPage <= limit) {
      return true;
    } else {
      return false;
    }
  };

  const isScreenLarge = useMediaQuery("(min-width:1000px)");
  /**
   * Función que elimina las páginas en vista de dispositivo móvil
   */
  const handleRemovePage = (index) => {
    //Tomamos los valores actuales en la fila
    const currentField = getValues(`mappingFields.${index}`);
    if (currentField.field) {
      //Si existe valor de field, lo marcamos deseleccionado
      dispatchFields({
        type: "deselect",
        field: currentField.field,
        typeOfAction: "updateArray",
      });
    }

    if (currentField.position) {
      // Si existe valor de position, lo marcamos deseleccionado
      dispatchPosition({ type: "deselect", position: currentField.position });
    }
    //Eliminamos la fila del arreglo de campos
    remove(index);
    //Si la página eliminada es la última, entonces se retrocede una página para no salir del arreglo
    if (currentPage >= fields.length - 1) {
      previousPage();
    }
  };

  return (
    <Accordion className="filters-load-mapping-edit">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid
          container
          columnSpacing={1}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Grid item>
            <p className="heading__text-grey">
              {t("App.loadMapping.createModal.fields")}
            </p>
          </Grid>
          <Grid item marginTop={"0.5%"}>
            <Tooltip 
            title={t("App.loadMapping.createModal.tooltip")} 
            placement={isScreenLarge ? "right" : "bottom"}>
              <InfoOutlinedIcon sx={{ fontSize: "20px", color: "#543AB4" }} />
            </Tooltip>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails 
             sx={{
              padding: "0px 2rem !important",
              border: "none !important",
              backgroundColor: "#fff !important"
              }}
      >
        {isDesktop ? (
          <Grid
            container
            direction="row"
            rowSpacing={3}
            className="scroll-x only-scroll-y"
            sx={{ maxHeight: "245px",}}
          >
            {fields.map((currentField, index) => (
              <Grid item key={currentField.key}>
                <Grid container columnSpacing={2}>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item className="form__label label__text-grey">
                        {t("App.loadMapping.createModal.field")}
                      </Grid>
                      <Grid item>
                        <Controller
                          control={control}
                          name={`mappingFields.${index}.field`}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              disabled={currentField.disabled}
                              displayEmpty
                              placeholder={t(
                                "App.loadMapping.createModal.field"
                              )}
                              value={value}
                              className={
                                errors?.mappingFields?.[index]?.field
                                  ? "select__filters__errors__sm"
                                  : "select__filters__sm"
                              }
                              onChange={(event) => {
                                handleChangeFieldType(
                                  event.target.value,
                                  index
                                );
                                onChange(event.target.value);
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  {t("App.loadMapping.createModal.field")}
                                </em>
                              </MenuItem>
                              {fieldsList.map((fieldType) => {
                                return (
                                  <MenuItem
                                    sx={{
                                      display: fieldType.selected ? "none" : "",
                                    }}
                                    key={fieldType.value}
                                    value={fieldType.value}
                                  >
                                    {fieldType.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </Grid>
                      {errors?.mappingFields?.[index]?.field && (
                        <Grid
                          item
                          sx={{
                            margin: "1px 0 0 0",
                            color: "#e52900",
                          }}
                        >
                          {errors.mappingFields[index].field.message}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item className="form__label label__text-grey">
                        {t("App.loadMapping.createModal.position.title")}
                      </Grid>
                      <Grid item>
                        <Controller
                          control={control}
                          name={`mappingFields.${index}.position`}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              displayEmpty
                              placeholder={t(
                                "App.loadMapping.createModal.position.title"
                              )}
                              value={value}
                              className={
                                errors?.mappingFields?.[index]?.position
                                  ? "select__filters__errors__sm"
                                  : "select__filters__sm"
                              }
                              onChange={(event) => {
                                handleChangePosition(event.target.value, index);
                                onChange(event.target.value);
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  {t(
                                    "App.loadMapping.createModal.position.title"
                                  )}
                                </em>
                              </MenuItem>
                              {positionList.map((position) => {
                                return (
                                  <MenuItem
                                    sx={{
                                      display: position.selected ? "none" : "",
                                    }}
                                    key={position.value}
                                    value={position.value}
                                  >
                                    {position.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </Grid>
                      {errors?.mappingFields?.[index]?.position && (
                        <Grid
                          item
                          sx={{
                            margin: "1px 0 0 0",
                            color: "#e52900",
                          }}
                        >
                          {errors.mappingFields[index].position.message}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item className="form__label label__text-grey">
                        {t("App.loadMapping.createModal.format.title")}
                      </Grid>
                      <Grid item>
                        <Controller
                          control={control}
                          name={`mappingFields.${index}.format`}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              displayEmpty
                              placeholder={t(
                                "App.loadMapping.createModal.format.title"
                              )}
                              value={value}
                              className={
                                errors?.mappingFields?.[index]?.format
                                  ? "select__filters__errors__sm"
                                  : "select__filters__sm"
                              }
                              onChange={(event) => {
                                onChange(event.target.value);
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  {t(
                                    "App.loadMapping.createModal.format.title"
                                  )}
                                </em>
                              </MenuItem>
                              {getFormatByFieldId(index).map((formatType) => {
                                return (
                                  <MenuItem
                                    key={formatType.value}
                                    value={formatType.value}
                                  >
                                    {formatType.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </Grid>
                      {errors?.mappingFields?.[index]?.format && (
                        <Grid
                          item
                          sx={{
                            margin: "1px 0 0 0",
                            color: "#e52900",
                          }}
                        >
                          {errors.mappingFields[index].format.message}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {index > 2 && (
                    <Grid
                      item
                      className="col-flex"
                      style={{ marginTop: "2.2%" }}
                    >
                      <Grid container direction="row" columnSpacing={1}>

                        {index > disabledFieldsIndex() && (
                          <Grid item md={6}>
                            <IconButton
                              onClick={() => {
                                handleRemoveField(index);
                              }}
                              className={"addPermission__delete"}
                            >
                              <ClearIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                          </Grid>
                        )}
                        {index === fields.length - 1 &&
                          fields.length !== fieldsLength && (
                            <Grid item md={6}>
                              <IconButton
                                className={"addPermission__button"}
                                onClick={handleAddField}
                              >
                                <AddIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          //Version mobile
          <Grid
            container
            direction="column"
            rowSpacing={2}
            className="scroll-x only-scroll-y"
          >
            {fields.map(
              (currentField, index) =>
                index === currentPage && (
                  <>
                    <Grid
                      item
                      className="col-flex mapping-page__btnContainer"
                      style={{ marginTop: "1.5%" }}
                    >
                      <Grid
                        container
                        direction="row"
                        columnSpacing={1}
                        sx={{ minHeight: "48px" }}
                      >
                        <Grid item sm={6} sx={{ width: "100%" }}>
                          <IconButton
                            disabled={handleAddFieldMobile()}
                            className={
                              handleAddFieldMobile()
                                ? "mapping-page__addField__disabled"
                                : "mapping-page__addField"
                            }
                            onClick={handleAddField}
                          >
                            <AddIcon sx={{ fontSize: 20 }} />
                            {t("App.loadMapping.createModal.addField")}
                          </IconButton>
                        </Grid>
                        <Grid item sm={6} sx={{ width: "100%" }}>
                          <IconButton
                            disabled={handleRemoveFieldMobile()}
                            className={
                              handleRemoveFieldMobile()
                                ? "mapping-page__removeField__disabled"
                                : "mapping-page__removeField"
                            }
                            onClick={() => {
                              handleRemovePage(index);
                            }}
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                            {t("App.loadMapping.createModal.deleteField")}
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item key={currentField.key}>
                      <Grid container columnSpacing={2} rowSpacing={2}> 
                        <Grid item sx={{ width: "100%" }}>
                          <Grid container direction="column">
                            <Grid item className="form__label label__text-grey">
                              {t("App.loadMapping.createModal.field")}
                            </Grid>
                            <Grid item>
                              <Controller
                                control={control}
                                name={`mappingFields.${index}.field`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    disabled={currentField.disabled}
                                    displayEmpty
                                    placeholder={t(
                                      "App.loadMapping.createModal.field"
                                    )}
                                    value={value}
                                    className={
                                      errors?.mappingFields?.[index]?.field
                                        ? "select__filters__errors__full"
                                        : "select__filters__full"
                                    }
                                    onChange={(event) => {
                                      handleChangeFieldType(
                                        event.target.value,
                                        index
                                      );
                                      onChange(event.target.value);
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em>
                                        {t("App.loadMapping.createModal.field")}
                                      </em>
                                    </MenuItem>
                                    {fieldsList.map((fieldType) => {
                                      return (
                                        <MenuItem
                                          sx={{
                                            display: fieldType.selected
                                              ? "none"
                                              : "",
                                          }}
                                          key={fieldType.value}
                                          value={fieldType.value}
                                        >
                                          {fieldType.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              />
                            </Grid>
                            {errors?.mappingFields?.[index]?.field && (
                              <Grid
                                item
                                sx={{
                                  margin: "1px 0 0 0",
                                  color: "#e52900",
                                }}
                              >
                                {errors.mappingFields[index].field.message}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                          <Grid container direction="column">
                            <Grid item className="form__label label__text-grey">
                              {t("App.loadMapping.createModal.position.title")}
                            </Grid>
                            <Grid item>
                              <Controller
                                control={control}
                                name={`mappingFields.${index}.position`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    displayEmpty
                                    placeholder={t(
                                      "App.loadMapping.createModal.position.title"
                                    )}
                                    value={value}
                                    className={
                                      errors?.mappingFields?.[index]?.position
                                        ? "select__filters__errors__full"
                                        : "select__filters__full"
                                    }
                                    onChange={(event) => {
                                      handleChangePosition(
                                        event.target.value,
                                        index
                                      );
                                      onChange(event.target.value);
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em>
                                        {t(
                                          "App.loadMapping.createModal.position.title"
                                        )}
                                      </em>
                                    </MenuItem>
                                    {positionList.map((position) => {
                                      return (
                                        <MenuItem
                                          sx={{
                                            display: position.selected
                                              ? "none"
                                              : "",
                                          }}
                                          key={position.value}
                                          value={position.value}
                                        >
                                          {position.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              />
                            </Grid>
                            {errors?.mappingFields?.[index]?.position && (
                              <Grid
                                item
                                sx={{
                                  margin: "1px 0 0 0",
                                  color: "#e52900",
                                }}
                              >
                                {errors.mappingFields[index].position.message}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                          <Grid container direction="column">
                            <Grid item className="form__label label__text-grey">
                              {t("App.loadMapping.createModal.format.title")}
                            </Grid>
                            <Grid item>
                              <Controller
                                control={control}
                                name={`mappingFields.${index}.format`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    displayEmpty
                                    placeholder={t(
                                      "App.loadMapping.createModal.format.title"
                                    )}
                                    value={value}
                                    className={
                                      errors?.mappingFields?.[index]?.format
                                        ? "select__filters__errors__full"
                                        : "select__filters__full"
                                    }
                                    onChange={(event) => {
                                      onChange(event.target.value);
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em>
                                        {t(
                                          "App.loadMapping.createModal.format.title"
                                        )}
                                      </em>
                                    </MenuItem>
                                    {getFormatByFieldId(index).map(
                                      (formatType) => {
                                        return (
                                          <MenuItem
                                            key={formatType.value}
                                            value={formatType.value}
                                          >
                                            {formatType.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                )}
                              />
                            </Grid>
                            {errors?.mappingFields?.[index]?.format && (
                              <Grid
                                item
                                sx={{
                                  margin: "1px 0 0 0",
                                  color: "#e52900",
                                }}
                              >
                                {errors.mappingFields[index].format.message}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
            )}
            {editedItem.typeDocument && (
              <Grid className="mapping-page__container">
                <Grid item sx={{ minWidth: "46px" }}>
                  {showPreviousPageButton() && (
                    <IconButton
                      className="mapping-page__btn"
                      onClick={previousPage}
                    >
                      <LeftArrow sx={{ fontSize: 20 }} />
                    </IconButton>
                  )}
                </Grid>
                <Grid item className="mapping-page__text">
                  <p>
                    {" "}
                    {t("App.loadMapping.createModal.nField")} {currentPage + 1}
                  </p>
                </Grid>
                <Grid item sx={{ minWidth: "46px" }}>
                  {showNextPageButton() && (
                    <IconButton
                      className="mapping-page__btn"
                      onClick={nextPage}
                    >
                      <RightArrow sx={{ fontSize: 20 }} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

//Definición de propTypes
FieldsAccordionEdit.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.shape({ mappingFields: PropTypes.array }),
  t: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  isStoreDetailSelected: PropTypes.bool,
  isCheckEdited: PropTypes.bool,
  editedItem: PropTypes.object,
};

export { FieldsAccordionEdit };
