import {
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalChangeMaster } from "../../../components/common/Modals/modalChangeMaster";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import {
  GetListReceivers,
  GetListTrader,
} from "../../../services/user/externalFiles/externalFilesService";
import { CODES } from "../../../consts/codes";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SelectMaster = ({
  currentType,
  setCurrentType,
  setType,
  type,
  setSender,
  sender,
  receiver,
  setReceiver,
}) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use State
   */
  const [openModalChangeType, setOpenModalChangeType] = useState(null);
  const [desiredType, setDesiredType] = useState(null);
  const [receivers, setReceivers] = useState([]);
  const [senders, setSenders] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  /**-
   * yup
   */
  const schema = yup.object().shape({});
  /**
   *
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use Effect
   */

  /**
   * Obtener la lista de receptores y emisores al cargar el componente
   */
  useEffect(() => {
    if (userInfo) {
      if (userInfo.company.typeCompany === "C") {
        getReceivers();

        setSender(
          {
            companyName: userInfo.company.companyName,
            eanCompany: userInfo.company.eanCompany,
          }
          //userInfo.company.eanCompany
        );
      } else {
        getSenders();
        setReceiver(
          {
            companyName: userInfo.company.companyName,
            eanCompany: userInfo.company.eanCompany,
          }
          //userInfo.company.eanCompany
        );
      }
      // userInfo.company.typeCompany === "C"
      //   ? setSender(userInfo.company.eanCompany)
      //   : setReceiver(userInfo.company.eanCompany);
    }
  }, [userInfo]);

  /**
   * Handles
   */

  /**
   * Cambia el tipo de documento seleccionado
   * @param {*} event
   */
  const handleTypeChange = (event) => {
    const id = event.target.value;
    if (currentType !== "0") {
      setDesiredType(id);
      setOpenModalChangeType(true);
    } else {
      setCurrentType(id);
      setType(id === "1" ? "sales" : id === "2" ? "inventory" : "sales_stock");
    }
  };

  /**
   * Cambia el emisor seleccionado
   * @param {*} event
   */
  const handleSenderChange = (event) => {
    const id = event.target.value;
    setSender(id);
  };

  /**
   * Cambia el receptor seleccionado
   * @param {*} event
   */
  const handleReceiverChange = (event) => {
    const id = event.target.value;
    setReceiver(id);
  };

  /**
   * Obtiene la lista de receptores
   */
  const getReceivers = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetListReceivers();
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        let listCompanies = [];
        responseMessage
          .map((item) => item.companies)
          .map((item) => item.map((item) => listCompanies.push(item)));

        listCompanies = listCompanies.map((item) => {
          return {
            companyName: item.companyName,
            eanCompany: item.eanCompany,
          };
        });
        setReceivers(listCompanies);
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("-------------");
      console.log(error);
      console.log("-------------");
    }
  };

  /**
   * Obtiene la lista de emisores
   */
  const getSenders = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetListTrader();
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        let sendersAux = responseMessage.map((receiver) => {
          return {
            eanCompany: receiver.eanCompany,
            companyName: receiver.companyName,
          };
        });

        setSenders(sendersAux);
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("-------------");
      console.log(error);
      console.log("-------------");
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="grid__container__filled__rounded"
      spacing={1.5}
    >
      <Grid item xs={12} sm={12} md={3} lg={2.7} xl={3.7}>
        <p className="subheadingOne__subheading-one">
          {t("App.externalFiles.infoSelect")}
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={3.3} lg={3} xl={2.4} width={"100%"}>
         <label className="labelMaster__text-grey">
         {t("App.externalFiles.select.DocumentType")}
        </label>
        <Select
          className="select__border"
          onChange={handleTypeChange}
          value={currentType}
          id="type"
          name="type"
          labelId="type"
          width="100%"
          minWidth="100%"
          sx={{ width: "100%", minWidth: "100%" }}
        >
          <MenuItem disabled value="0">
            <em> {t("App.externalFiles.select.placeholder")}</em>
          </MenuItem>

          <MenuItem value="1">
            <em> {t("App.externalFiles.select.sales")}</em>
          </MenuItem>
          <MenuItem value="2">
            <em> {t("App.externalFiles.select.inventory")}</em>
          </MenuItem>
          <MenuItem value="3">
            <em> {t("App.externalFiles.select.salesStock")}</em>
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={12} md={2.15} lg={3} xl={2.4} width={"100%"}>
      <label className="labelMaster__text-grey">
         {t("App.externalFiles.select.issuer")}
        </label>
        <Controller
          control={control}
          name="sender"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disableClearable
              disabled={
                currentType === "0" ||
                (userInfo && userInfo.company.typeCompany === "C")
              }
              options={senders}
              getOptionLabel={(option) => option.companyName}
              noOptionsText={t("App.listUsers.noOptions")}
              isOptionEqualToValue={(option, value) =>
                option.eanCompany === value.eanCompany
              }
              className={"select-contactOne__filters"}
               renderOption={(props, option, { selected }) => (
                <li {...props} key={option.eanCompany}>
                  {option.companyName}
                </li>
              )}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  inputProps={{
                    ...inputProps,
                    readOnly: false,
                    endAdornment: "",
                  }}
                />
              )}
              onChange={(event, values, reason) => {
                onChange(values);
                setSender(
                  {
                    //companyType: values.company.typeCompany,
                    companyName: values.companyName,
                    eanCompany: values.eanCompany,
                  }
                  //values.eanCompany
                );
              }}
              value={
                userInfo && userInfo.company.typeCompany === "C"
                  ? {
                      companyName: userInfo.company.companyName,
                      eanCompany: userInfo.company.eanCompany,
                    }
                  : value || {
                      companyName: "",
                      eanCompany: "",
                    }
              }
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={2.15}
        lg={3}
        xl={2.4}
        width={"100%"}
      >
        <label className="labelMaster__text-grey">
         {t("App.externalFiles.select.receiver")}
        </label>
        <Controller
          control={control}
          name="receiver"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disableClearable
              disabled={
                currentType === "0" ||
                (userInfo && userInfo.company.typeCompany === "F")
              }
              options={receivers}
              getOptionLabel={(option) => option.companyName}
              noOptionsText={t("App.listUsers.noOptions")}
              isOptionEqualToValue={(option, value) =>
                option.eanCompany === value.eanCompany
              }
              className={"select-contactOne__filters"}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.eanCompany}>
                  {option.companyName}
                </li>
              )}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  inputProps={{
                    ...inputProps,
                    readOnly: false,
                    endAdornment: "",
                  }}
                />
              )}
              onChange={(event, values, reason) => {
                onChange(values);
                setReceiver({
                  companyName: values.companyName,
                  eanCompany: values.eanCompany,
                });
              }}
              value={
                userInfo && userInfo.company.typeCompany === "F"
                  ? {
                      companyName: userInfo.company.companyName,
                      eanCompany: userInfo.company.eanCompany,
                    }
                  : value || {
                      companyName: "",
                      eanCompany: "",
                    }
              }
            />
          )}
        />
      </Grid>
      {/* Modal para confirmar el cambio de tipo de documento */}
      <ModalChangeMaster
        open={openModalChangeType || false}
        setOpen={setOpenModalChangeType}
        desiredType={desiredType}
        setCurrentMaster={setCurrentType}
        titleModal={t("App.externalFiles.modal.changeTitle")}
        textModal={t("App.externalFiles.modal.changeText")}
      />
      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default SelectMaster;
