import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import { CODES } from "../../../consts/codes";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../../services/user/deleteAndReload/deleteAndReload";
import PropTypes from "prop-types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BaseInfoAccordion = ({
  t,
  control,
  errors,
  setValue,
  setDocumentType,
  documentType,
  setIsStoreDetailSelected,
  isStoreDetailSelected,
}) => {
  /**
   * Const
   */
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const documentTypeList = [
    {
      label: t("App.loadMapping.createModal.documentType.sales"),
      value: "sales",
    },
    {
      label: t("App.loadMapping.createModal.documentType.stock"),
      value: "stock",
    },
    {
      label: t("App.loadMapping.createModal.documentType.saleStock"),
      value: "sales_stock",
    },
  ];

  const fileTypeList = [
    { label: ".CSV", value: "csv" },
    { label: ".TXT", value: "txt" },
    { label: ".XLS", value: "xls" },
    { label: ".XLSX", value: "xlsx" },
  ];

  const typeOfDelimiter = {
    format: [
      {
        label: t("App.loadMapping.createModal.delimiter.semicolon"),
        value: ";",
      },
      { label: t("App.loadMapping.createModal.delimiter.comma"), value: "," },
    ],
    noneFormat: [{ label: "N.A.", value: "na" }],
  };

  const dataReadingList = [
    {
      label: t("App.loadMapping.createModal.dataReading.firstRow"),
      value: "1",
    },
    {
      label: t("App.loadMapping.createModal.dataReading.secondRow"),
      value: "2",
    },
  ];

  /**
   * Use State
   */

  //Listas de campos
  const [countryList, setCountryList] = useState([]);
  const [issuerList, setIssuerList] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const [delimiterList, setDelimiterList] = useState([]);

  //Carga de campos
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  //Campos seleccionados para deshabilitar campos dependientes
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [isDocumentTypeSelected, setIsDocumentTypeSelected] = useState(false);
  const [isFileTypeSelected, setIsFileTypeSelected] = useState(false);
  const [isDelimiterSelected, setIsDelimiterSelected] = useState(false);

  //mediaquey
  const isScreenLarge = useMediaQuery("(min-width:1000px)");

  /**
   * Use Effect
   */

  /**
   * Función que consulta los países registrados en la plataforma
   */
  useEffect(() => {
    const getCountriesRequest = async () => {
      try {
        setIsLoadingCountries(true);
        const {
          status,
          data: { responseCode, responseMessage },
        } = await GetGeographiesList();

        if (
          status === CODES.COD_RESPONSE_SUCCESS &&
          responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          setCountryList(responseMessage);
        } else {
          setCountryList([]);
        }
        setIsLoadingCountries(false);
      } catch (error) {
        console.log(
          "================= ERROR at BaseInfoAccordion.jsx ================="
        );
        console.log(error);
        console.log("===============================================");
      }
    };
    getCountriesRequest();
  }, []);

  /**
   * Request
   */

  /**
   * Petición para traer los emisores activos por país
   * @param {*} currentCountry país de los emisores
   * @returns lista de emisores
   */
  const getIssuersRequest = async (currentCountry) => {
    const {
      status,
      data: { responseCode, responseMessage },
    } = await GetTradersService({ country: currentCountry });

    if (
      status === CODES.COD_RESPONSE_SUCCESS &&
      responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      return responseMessage;
    } else {
      return [];
    }
  };

  /**
   * Petición para traer los receptores activos por país
   * @param {*} currentCountry país de receptores
   * @returns lista de receptores
   */
  const getReceiversRequest = async (currentCountry) => {
    const {
      status,
      data: { responseCode, responseMessage },
    } = await GetSuppliersService({ country: currentCountry });

    if (
      status === CODES.COD_RESPONSE_SUCCESS &&
      responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      return responseMessage;
    } else {
      return [];
    }
  };

  /**
   * Handles
   */

  /**
   * Función que maneja los cambios sobre el formulario cuando un país es seleccionado
   * @param {*} currentCountry país seleccionado
   */
  const handleCountryChange = async (currentCountry) => {
    try {
      setValue("issuers", []);
      setValue("receiver", null);
      if (currentCountry) {
        setIsLoadingCompanies(true);

        //Cargamos los emisores
        const issuersFinalList = await getIssuersRequest(
          currentCountry.country
        );
        issuersFinalList.unshift({
          eanCompany: t("App.downloadSalesForce.selectAll"),
          companyName: t("App.downloadSalesForce.selectAll"),
        });
        setIssuerList(issuersFinalList);

        //Cargamos los receptores
        const receiversFinalList = await getReceiversRequest(
          currentCountry.country
        );
        setReceiverList(receiversFinalList);

        setIsLoadingCompanies(false);
        setIsCountrySelected(true);
      } else {
        setIsCountrySelected(false);
      }
    } catch (error) {
      console.log(
        "====================== ERROR at BaseInfoAccordion.jsx ==================="
      );
      console.log(error);
      console.log("======================================");
    }
  };

  /**
   * Función que maneja los cambios en el formulario cuando los emisores son seleccionados
   * @param {*} currentIssuersList emisores seleccionados
   * @param {*} reason tipo de selección: selectOption, removeOption
   * @param {*} detail opción seleccionada
   * @returns para romper el flujo de ejecución
   */
  const handleIssuersChange = (currentIssuersList, reason, detail) => {
    let includeSelectAll = false;

    //Si la opción que se seleccionó actualmente es selectAll
    if (detail) {
      const option = detail.option;

      if (
        reason === "removeOption" &&
        option.eanCompany === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("issuers", []);
        return;
      }
    }

    //Reviso si el select all fue seleccionado
    for (const issuer of currentIssuersList) {
      if (issuer.eanCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    //Si el selectAll fue seleccionado -> Selecciono toda lista

    if (
      (includeSelectAll && reason === "selectOption") ||
      (!includeSelectAll && currentIssuersList.length === issuerList.length - 1)
    ) {
      setValue("issuers", issuerList);
    }

    //Si selectAll está seleccionado y se deselecciona alguna opción
    if (includeSelectAll && reason === "removeOption") {
      const withoutSelectAll = currentIssuersList.filter(
        (issuer) => issuer.eanCompany !== t("App.downloadSalesForce.selectAll")
      );

      setValue("issuers", withoutSelectAll);
    }
  };

  /**
   * Función que maneja cambios en el formulario cuando se selecciona el tipo de documento
   */
  const handleChangeDocumentType = (currentDocumentType) => {
    setValue("fileType", "");
    setValue("delimiter", "");
    setValue("dataReading", "");

    setDocumentType(currentDocumentType);
    setIsStoreDetailSelected(false);

    if (currentDocumentType) {
      setIsDocumentTypeSelected(true);
    } else {
      setIsDocumentTypeSelected(false);
      setIsFileTypeSelected(false);
      setIsDelimiterSelected(false);
    }
  };

  /**
   * Función que maneja cambios sobre el formulario cuando se selecciona el tipo de archivo
   * @param {} currentFileType tipo de archivo seleccionado
   */
  const handleChangeFileType = (currentFileType) => {
    setValue("delimiter", "");
    setValue("dataReading", "");
    if (currentFileType) {
      if (currentFileType === "csv" || currentFileType === "txt") {
        setDelimiterList(typeOfDelimiter.format);
      } else {
        setDelimiterList(typeOfDelimiter.noneFormat);
      }
      setIsFileTypeSelected(true);
    } else {
      setIsFileTypeSelected(false);
    }
  };

  /**
   * Función que maneja la selección del detalle a tienda
   */

  const handleStoreDetail = () => {
    setIsStoreDetailSelected(!isStoreDetailSelected);
  };

  return (
    <Accordion defaultExpanded  className="filters-load-mapping">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} >
        <p className="heading__text-grey">
          {t("App.loadMapping.createModal.baseInfo")}
        </p>
      </AccordionSummary>
      <AccordionDetails
       sx={{
        padding: "0px 2rem !important",
        border: "none !important",
        backgroundColor: "#fff !important"
        }}>
        <Grid container rowSpacing={3}>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            xs={12}
            sm={6}
            className="base-info__container"
          >
            <Grid container direction="column">
              <Grid
                item
                className="form__label label__text-grey base-info__select"
              >
                {t("App.listUsers.country")}
              </Grid>
              <Grid item className="base-info__select">
                <Controller
                  name={"country"}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={`${
                        errors.country
                          ? "select__filters__errors__sm"
                          : "select__filters__sm"
                      }
                          base-info__input`}
                      options={countryList}
                      getOptionLabel={(option) => option.countryName}
                      isOptionEqualToValue={(option, value) =>
                        option.country == value.country
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            placeholder={t("App.listUsers.country")}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoadingCountries ? (
                                    <CircularProgress
                                      sx={{ color: "#4DCAFA" }}
                                      size={15}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      onChange={(event, values, reason) => {
                        onChange(values);
                        handleCountryChange(values);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                />
              </Grid>

              {errors?.country && (
                <Grid
                  item
                  sx={{
                    margin: "1px 0 0 0",
                    color: "#e52900",
                  }}
                >
                  {errors?.country?.country
                    ? errors.country.country.message
                    : errors.country.message}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            sm={6}
            className="base-info__container"
          >
            {" "}
            <Grid container direction="column">
              <Grid
                item
                className="form__label label__text-grey base-info__select"
              >
                {t("App.loadMapping.createModal.issuer")}
              </Grid>

              <Grid item className="base-info__select">
                <Controller
                  control={control}
                  name="issuers"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={!(isCountrySelected && !isLoadingCompanies)}
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={`${
                        errors.issuers
                          ? "select__filters__errors__sm"
                          : "select__filters__sm"
                      }
                          base-info__input`}
                      disableCloseOnSelect
                      options={issuerList}
                      multiple
                      limitTags={1}
                      isOptionEqualToValue={(option, value) =>
                        option.eanCompany === value.eanCompany
                      }
                      getOptionLabel={(option) => option.companyName}
                      value={value}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.eanCompany + ""}>
                          <Checkbox
                            icon={icon}
                            className="autoComplete__checkbox"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.companyName}
                        </li>
                      )}
                      onChange={(event, values, reason, detail) => {
                        onChange(values);
                        handleIssuersChange(values, reason, detail);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            placeholder={
                              value.length === 0
                                ? t("App.loadMapping.createModal.issuer")
                                : undefined
                            }
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoadingCompanies ? (
                                    <CircularProgress
                                      sx={{ color: "#4DCAFA" }}
                                      size={15}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      renderTags={(value, getTagProps) => {
                        const numTags = value.length;
                        const limitTags = 1;
                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                sx={{
                                  width: { xs: "4.3rem", lg: "60%"},
                                }}
                                key={option.eanCompany}
                                size="small"
                                label={`${option.companyName}`}
                              />
                            ))}
                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {errors?.issuers && (
              <Grid
                item
                sx={{
                  margin: "1px 0 0 0",
                  color: "#e52900",
                }}
              >
                {errors.issuers.message}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            sm={6}
            className="base-info__container"
          >
            {" "}
            <Grid container direction="column">
              <Grid
                item
                className="form__label label__text-grey base-info__select"
              >
                {t("App.loadMapping.createModal.receiver")}
              </Grid>
              <Grid item className="base-info__select">
                <Controller
                  name={"receiver"}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.listUsers.noOptions")}
                      disabled={!(isCountrySelected && !isLoadingCompanies)}
                      className={`${
                        errors.receiver
                          ? "select__filters__errors__sm"
                          : "select__filters__sm"
                      }
                          base-info__input`}
                      options={receiverList}
                      getOptionLabel={(option) => option.companyName}
                      isOptionEqualToValue={(option, value) =>
                        option.eanCompany == value.eanCompany
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            placeholder={t(
                              "App.loadMapping.createModal.receiver"
                            )}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoadingCompanies ? (
                                    <CircularProgress
                                      sx={{ color: "#4DCAFA" }}
                                      size={15}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      onChange={(event, values, reason) => {
                        onChange(values);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                />
              </Grid>
              {errors?.receiver && (
                <Grid
                  item
                  sx={{
                    margin: "1px 0 0 0",
                    color: "#e52900",
                  }}
                >
                  {errors.receiver.message}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            xs={12}
            sm={6}
            className="base-info__container"
          >
            {" "}
            <Grid container direction="column">
              <Grid
                item
                className="form__label label__text-grey base-info__select"
              >
                {t("App.loadMapping.createModal.documentType.title")}
              </Grid>
              <Grid item className="base-info__select">
                <Controller
                  name={"documentType"}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={`${
                        errors.documentType
                          ? "select__filters__errors__sm"
                          : "select__filters__sm"
                      }
                          base-info__input`}
                      options={documentTypeList}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.value == value.value
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            placeholder={t(
                              "App.loadMapping.createModal.documentType.title"
                            )}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        );
                      }}
                      onChange={(event, values, reason) => {
                        onChange(values);
                        handleChangeDocumentType(values);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                />
              </Grid>
              {errors?.documentType && (
                <Grid
                  item
                  sx={{
                    margin: "1px 0 0 0",
                    color: "#e52900",
                  }}
                >
                  {errors.documentType.message}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            xs={12}
            sm={6}
            className="base-info__container"
          >
            {" "}
            <Grid container direction="column">
              <Grid
                item
                className="form__label label__text-grey base-info__select"
              >
                {t("App.loadMapping.createModal.archiveType")}
              </Grid>
              <Grid item className="base-info__select">
                <Controller
                  control={control}
                  name="fileType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      displayEmpty
                      disabled={!isDocumentTypeSelected}
                      placeholder={t("App.loadMapping.createModal.archiveType")}
                      value={value}
                      className={`${
                        errors.country
                          ? "select__filters__errors__sm"
                          : "select__filters__sm"
                      }
                          base-info__input`}
                      onChange={(event) => {
                        onChange(event.target.value);
                        handleChangeFileType(event.target.value);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>{t("App.loadMapping.createModal.archiveType")}</em>
                      </MenuItem>
                      {fileTypeList.map((fileType) => {
                        return (
                          <MenuItem key={fileType.value} value={fileType.value}>
                            {fileType.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </Grid>
              {errors?.fileType && (
                <Grid
                  item
                  sx={{
                    margin: "1px 0 0 0",
                    color: "#e52900",
                  }}
                >
                  {errors.fileType.message}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            xs={12}
            sm={6}
            className="base-info__container"
          >
            {" "}
            <Grid container direction="column">
              <Grid
                item
                className="form__label label__text-grey base-info__select"
              >
                {t("App.loadMapping.createModal.delimiter.title")}
              </Grid>
              <Grid item className="base-info__select">
                <Controller
                  control={control}
                  name="delimiter"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      displayEmpty
                      disabled={!isFileTypeSelected}
                      placeholder={t("App.loadMapping.createModal.archiveType")}
                      value={value}
                      className={`${
                        errors.country
                          ? "select__filters__errors__sm"
                          : "select__filters__sm"
                      }
                          base-info__input`}
                      onChange={(event) => {
                        onChange(event.target.value);
                        setIsDelimiterSelected(true);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>
                          {t("App.loadMapping.createModal.delimiter.title")}
                        </em>
                      </MenuItem>
                      {delimiterList.map((delimiter) => {
                        return (
                          <MenuItem
                            key={delimiter.value}
                            value={delimiter.value}
                          >
                            {delimiter.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </Grid>
              {errors?.delimiter && (
                <Grid
                  item
                  sx={{
                    margin: "1px 0 0 0",
                    color: "#e52900",
                  }}
                >
                  {errors.delimiter.message}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}
            md={12}
            xs={12}
            sm={12}
            className="base-info__container"
          >
            {" "}
            <Grid container direction="column">
              <Grid
                item
                className="form__label label__text-grey base-info__select"
              >
                {t("App.loadMapping.createModal.dataReading.title")}
              </Grid>
              <Grid item className="base-info__select">
                <Controller
                  name="dataReading"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      displayEmpty
                      disabled={!isDelimiterSelected}
                      value={value}
                      className={`${
                        errors.dataReading
                          ? "select__filters__errors"
                          : "select__filters"
                      } base-info__input`}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>
                          {t("App.loadMapping.createModal.dataReading.title")}
                        </em>
                      </MenuItem>
                      {dataReadingList.map((dataRead) => {
                        return (
                          <MenuItem key={dataRead.value} value={dataRead.value}>
                            {dataRead.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  control={control}
                />
              </Grid>
              {errors?.dataReading && (
                <Grid
                  item
                  sx={{
                    margin: "1px 0 0 0",
                    color: "#e52900",
                  }}
                >
                  {errors.dataReading.message}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              className="store__detail__container"
            >
              <Grid item>
                <Checkbox
                  onChange={handleStoreDetail}
                  checkedIcon={checkedIcon}
                  checked={
                    documentType
                      ? documentType.value === "sales"
                        ? isStoreDetailSelected
                        : false
                      : false
                  }
                  disabled={
                    documentType
                      ? documentType.value === "sales"
                        ? ""
                        : "disabled"
                      : "disabled"
                  }
                />
              </Grid>
              <Grid
                item
                paddingLeft={"1px !important"}
                className="form__label label__text-grey check__container tooltip__container"
              >
                <p
                  className={
                    documentType
                      ? documentType.value === "sales"
                        ? "heading__primary-one"
                        : "heading__text-disabled"
                      : "heading__text-disabled"
                  }
                >
                  {t("App.loadMapping.createModal.storeDetail.title")}
                </p>
              </Grid>
              <Grid item className="store__detail__tooltip">
                <Tooltip
                  title={t("App.loadMapping.createModal.baseTooltip")}
                  placement={isScreenLarge ? "right" : "bottom"}
                >
                  <InfoOutlinedIcon
                    sx={{ fontSize: "20px", color: "#543AB4" }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
BaseInfoAccordion.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.shape({
    country: PropTypes.object,
    issuers: PropTypes.object,
    receiver: PropTypes.object,
    documentType: PropTypes.object,
    fileType: PropTypes.object,
    delimiter: PropTypes.object,
    dataReading: PropTypes.object,
    isStoreDetailSelected: PropTypes.object,
  }),
  t: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export { BaseInfoAccordion };
