import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { REGEXP } from "../../../consts/regexp";
import { getUser } from "../../../pages/userSlice";
import {
  CreateUsers,
  GetActiveProduct,
  GetRolesByProduct,
} from "../../../services/admin/adminServices";
import { AvailableCompaniesPermissions } from "../../../services/user/consultRolesService";
import ModalInfo from "../Modals/modalInfo";
import PermissionsRegisters from "./permissionsRegisters";
import { UserInfoComponent } from "./userInfoComponent";

const CreateUser = ({
  currentCompany,
  role,
  companyId,
  country,
  fetchUsers,
  openCreateUser,
  setOpenCreateUser,
}) => {
  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * useTranslation
   */
  const { t } = useTranslation();
  /**
   * Schema
   */
  const schema = yup.object().shape({
    name: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString")),
    lastNames: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString")),
    email: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .email(t("App.validationMessages.email"))
      .required(t("App.validationMessages.required")),
    confirmEmail: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .oneOf([yup.ref("email"), null], t("App.validationMessages.confirmEmail"))
      .required(t("App.validationMessages.required")),
    phone: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .nullable()
      .matches(
        REGEXP.SPECIAL_QA2,
        t("App.validationMessages.onlyAlphanumeric")
      ),

    permissions: yup.array().of(
      yup.object().shape({
        productList: yup
          .string()
          .required(t("App.validationMessages.required")),
        companyList: yup
          .array()
          .of(
            yup.object().shape({
              nameCompany: yup.string(),
              renameCompany: yup.string(),
              country: yup.string(),
              eanCompany: yup.string(),
            })
          )
          .min(1, t("App.validationMessages.required"))
          .typeError(t("App.validationMessages.required")),
        roleList: yup
          .string()
          .required(t("App.validationMessages.required"))
          .typeError(t("App.validationMessages.required")),
        status: yup.string().required(t("App.validationMessages.required")),
      })
    ),
  });

  /**
   * useForm
   */
  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      permissions: [],
      name: "",
      lastNames: "",
      email: "",
      confirmEmail: "",
      phone: null,
    },
  });

  /**
   * useFieldArray que maneja el arreglo de permisos
   */
  const {
    fields: permissionsFields,
    append: permissionsAppend,
    remove: permissionsRemove,
    update: permissionsUpdate,
  } = useFieldArray({
    control,
    name: "permissions",
  });

  /**
   * Use State
   */
  const userInfo = useSelector(getUser);

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [createUserData, setCreateUserData] = useState({});

  const [productsList, setProductsList] = useState([]);
  const [rolesMap, setRolesMap] = useState(new Map());
  const [companiesMap, setCompaniesMap] = useState(new Map());
  const [loadingProductsAndRoles, setLoadingProductsAndRoles] = useState(false);

  const [isBusinessGroup, setIsBusinessGroup] = useState(false);

  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);

    /**
   *  verifica si el tamaño de la pantalla es mobile o tablet 
   */
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 400);
        setIsScreenSmall(window.innerWidth < 1000);
      };
      handleResize();
    
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);  
  /**
   * useEffect para actualizar el tamaño de pantalla actual de la pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Deja la primera lista de empresas limpia en caso de que sea BG,
   * dado que en el primer append no se alcanza a determinar si es BG o no
   */
  useEffect(() => {
    if (isBusinessGroup) {
      setValue("permissions.0.companyList", []);
    }
  }, [isBusinessGroup]);

  /**
   * Carga los productos, roles y compañías para arreglo de permisos
   */
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        setLoadingProductsAndRoles(true);
        const finalProductsList = [];
        const rolesMap = new Map();
        const companiesMap = new Map();

        //Traigo los productos activos con al menos un usuario disponible de la empresa
        const initialProductsList = await fetchProductsList(companyId, country);

        for (const product of initialProductsList) {
          //Formateo el producto
          const productFormatted = setProductFormat(product);

          //Traigo los roles por el idMenuOption del producto
          const initialRolesList = await fetchRolesByIdMenuOption(
            product.idMenuOption
          );
          //Formateo los roles al formato requerido por el render
          const finalRolesList = setRolesFormat(initialRolesList);

          //Traigo las empresas asociadas por el idMenuOption del producto
          const finalCompaniesList = await fetchCompaniesByIdMenuOption(
            product.idMenuOption
          );

          //Guardo el producto en la lista de productos
          finalProductsList.push(productFormatted);

          //Guardo la lista de roles en el mapa de roles
          rolesMap.set(product.idMenuOption, finalRolesList);

          //Guardo la lista de compañías en el mapa de compañias
          companiesMap.set(product.idMenuOption, finalCompaniesList);
        }

        //Setteo la lista de productos
        setProductsList(sortListByAttribute(finalProductsList, "label"));
        //Setteo mapa de roles
        setRolesMap(rolesMap);
        //Setteo mapa de compañías
        setCompaniesMap(companiesMap);
        setLoadingProductsAndRoles(false);
      } catch (error) {
        console.log("============ Error createUser.jsx ============");
        console.log(error);
        console.log("========================================");
      }
    };
    if (openCreateUser) {
      fetchPermissions();
    }
  }, [openCreateUser]);

  /**
   * useEffect para añadir el primer permiso
   * y resetear los campos
   */
  useEffect(() => {
    if (openCreateUser) {
      addPermission();
    }
  }, [openCreateUser]);

  /**
   * Fetch
   */

  /**
   * Función trae los productos activos que tengan al menos un usuario disponible
   * @param {*} eanCompany ean de la empresa
   * @param {*} country país de la empresa
   * @returns lista de productos
   */
  const fetchProductsList = async (eanCompany, country) => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetActiveProduct({ eanCompany: eanCompany, country: country });

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        return responseMessage;
      } else {
        return [];
      }
    } catch (error) {
      console.log("============ Error createUser.jsx ============");
      console.log(error);
      console.log("========================================");
      return [];
    }
  };

  /**
   * Función que trae la lista de roles según el idMenuOption
   * @param {*} idMenuOption opción de menú
   * @returns lista de roles
   */
  const fetchRolesByIdMenuOption = async (idMenuOption) => {
    try {
      const isClient = currentCompany.eanCompany !== "CASSANDRA" ? true : false;
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetRolesByProduct({
        idMenuOption: idMenuOption,
        isClient: isClient,
      });

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        return responseMessage;
      } else {
        return [];
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      return [];
    }
  };

  /**
   * Función que trae la lista de empresas según el idMenuOption
   * @param {} idMenuOption opción de menú
   * @returns lista de empresas
   */
  const fetchCompaniesByIdMenuOption = async (idMenuOption) => {
    try {
      const obj = {
        country: currentCompany.country,
        eanCompany: currentCompany.eanCompany,
        idMenuOption,
      };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await AvailableCompaniesPermissions(obj);

      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setIsBusinessGroup(true);
            const list = responseMessage.map((item) => {
              return {
                nameCompany: `${item.nameCompany}`,
                renameCompany: `${item.country}-${item.nameCompany}`,
                country: item.country,
                eanCompany: item.eanCompany,
              };
            });

            return list;

          case CODES.COD_RESPONSE_ERROR_RESET_PASSWORD:
            setIsBusinessGroup(false);
            const currentCompanyInfo = {
              nameCompany: `${currentCompany.companyName}`,
              renameCompany: `${currentCompany.country}-${currentCompany.companyName}`,
              country: currentCompany.country,
              eanCompany: currentCompany.eanCompany,
            };

            return [currentCompanyInfo];

          default:
            //Setteo a true el businessGroup para permitir desplegar lista vacía y evidenciar que no
            //existen campos por seleccionar (error en la carga y evitar crash)
            setIsBusinessGroup(true);
            return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      return [];
    }
  };

  /**
   * Handles
   */

  /**
   * resetea los campos y
   * agrega el primer permiso
   */
  const addPermission = () => {
    reset();
    handleAddNewPermission();
  };

  /**
   * Función para cerrar el modal
   */
  const handleClose = async () => {
    await resetProductList();
    setOpenCreateUser(false);
    setLoadingCreate(false);
    reset();
  };

  /**
   * Método para actualizar el tamaño de pantalla actual
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * Funcion para ordenar una lista por un atributo
   */
  const sortListByAttribute = (list, attribute) => {
    return [...list].sort((a, b) => {
      if (a[attribute] < b[attribute]) {
        return -1;
      }
      if (a[attribute] > b[attribute]) {
        return 1;
      }
      return 0;
    });
  };

  /**
   * Función que formatea la lista de roles a el formato requerido para el render
   * @param {} rolesList lista de roles
   * @returns lista de roles formateada
   */
  const setRolesFormat = (rolesList) => {
    return rolesList.map((role) => ({
      label: role.nameRole,
      value: `{"idRole": "${role.idRole}", "nameRole": "${role.nameRole}"}`,
    }));
  };

  /**
   * Función que formatea el producto a el formato requerido para el render
   * @param {*} product producto
   * @returns producto formateado
   */
  const setProductFormat = (product) => {
    return {
      label: product.menuOptionName,
      value: `{ "idMenuOption": "${product.idMenuOption}", "menuOptionName": "${product.menuOptionName}" }`,
      isActive: true,
    };
  };

  /**
   * Función para generar un id genérico para renderizar lista de permisos
   * @returns id genérico
   */
  const generateId = () => {
    const id = Date.now().toString(32) + Math.random().toString(32);

    return id;
  };

  /**
   * Función para añadir un permiso
   */
  const handleAddNewPermission = () => {
    const userInfoCompany = {
      nameCompany: `${currentCompany.companyName}`,
      renameCompany: `${currentCompany.country}-${currentCompany.companyName}`,
      country: currentCompany.country,
      eanCompany: currentCompany.eanCompany,
    };
    const newPermission = {
      productSelection: false,
      companyList: isBusinessGroup ? [] : [userInfoCompany],
      key: generateId(),
    };

    permissionsAppend(newPermission);
  };

  /**
   *
   * Función para remover un permiso del arreglo de permisos
   *
   * @param {String} id
   * @param {Number} index
   */
  const handleRemovePermission = (index) => {
    const previousValue = getValues(`permissions.${index}.productList`);
    const convertedPrevious = previousValue
      ? JSON.parse(previousValue)
      : previousValue;

    const updatedProductList = productsList.map((item) => {
      const product = JSON.parse(item.value);
      const convertedCurrent = product.idMenuOption;

      if (convertedCurrent === convertedPrevious.idMenuOption) {
        return { ...item, selected: false };
      }

      return item;
    });

    setProductsList(sortListByAttribute(updatedProductList, "label"));

    permissionsRemove(index);
  };

  /**
   *
   * Función para actualizar el estado de productsList cuando el usuario hace click en el campo producto
   * @param {Event} event
   * @param {*} previous
   */
  const handleProductChange = (event, previous, index) => {
    const selectedProduct = event.target.value;
    const selectedConverted = JSON.parse(selectedProduct);
    const previousValue = previous;
    const previousConverted = previousValue ? JSON.parse(previousValue) : "";

    //Reseteo los campos de compañias, rol y estado

    if (isBusinessGroup) {
      setValue(`permissions.${index}.companyList`, []);
    }
    setValue(`permissions.${index}.roleList`, []);
    setValue(`permissions.${index}.status`, "");

    // Esto permite que se actualice el array y se habiliten los campos
    // de estado y rol
    const permission = getValues("permissions.0");
    permissionsUpdate(0, permission);

    const updatedProductList = productsList.map((item) => {
      const product = JSON.parse(item.value);
      const convertedValue = product.idMenuOption;

      if (convertedValue === selectedConverted.idMenuOption) {
        return { ...item, selected: true };
      } else if (convertedValue === previousConverted.idMenuOption) {
        return { ...item, selected: false };
      }

      return item;
    });

    setProductsList(sortListByAttribute(updatedProductList, "label"));
  };

  /**
   * Función que resetea la selección de la lista de productos
   */
  const resetProductList = () => {
    const resetList = productsList.map((item) => {
      return { ...item, selected: false };
    });

    setProductsList(sortListByAttribute(resetList, "label"));
  };

  /**
   *
   * Función que construye el objeto de usuario
   *
   * @param {Object} data información del usuario
   * @returns objeto de usuario según formato
   */
  const handlCreateUserObject = (data) => {
    //Construyo el objeto de usuario
    const userObject = {
      eanCompany: currentCompany.eanCompany,
      companyName: currentCompany.companyName,
      firstName: data.name,
      lastName: data.lastNames,
      email: data.email,
      phone: data.phone,
    };

    //Tomo el arreglo de permisos y lo construyo conforme cuerpos para el back
    const permissionsModification = data.permissions.map((permission) => {
      const parsedProduct = JSON.parse(permission.productList);
      const parsedRole = JSON.parse(permission.roleList);

      const companies = permission.companyList.map((company) => company);

      return {
        ...parsedProduct,
        ...parsedRole,
        lstCompanies: companies,
        state: Number(permission.status),
      };
    });

    //Construimos el arreglo final
    const userPermissionsArray = permissionsModification.map((permission) => {
      return { ...userObject, ...permission };
    });

    return userPermissionsArray;
  };

  /**
   * Función que retorna el título del modal según código de respuesta
   * @param {Number} responseCode código de respuesta
   * @returns título según código
   */
  const handleModalTitle = (responseCode) => {
    if (responseCode === 1001) {
      return t("App.userDetail.modalTitle.title2");
    } else if (responseCode === 1002) {
      return t("App.userDetail.modalTitle.title1");
    } else {
      return "";
    }
  };

  /**
   *
   * Function to create a new user
   *
   * @param {Object} data
   */
  const handleCreateUser = async (data) => {
    try {
      const userCreateArray = handlCreateUserObject(data);

      setLoadingCreate(true);

      const obj = {
        userCreateArray,
        country: currentCompany.country,
        userName: userInfo.firstName + " " + userInfo.lastName,
      };

      const createUserService = await CreateUsers(obj);

      if (
        createUserService.status === CODES.COD_RESPONSE_HTTP_OK &&
        createUserService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        fetchUsers();
      }

      const responseCode = createUserService.data.responseCode;

      setModalTitle(handleModalTitle(responseCode));
      setOpenModalInfo(true);
      setCreateUserData(createUserService);

      setLoadingCreate(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={openCreateUser}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "100%",
              maxWidth: "900px",
              maxHeight: "575px",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
              overflowX: "hidden", 
            },
          },
        }}
      >
        <DialogTitleContainer onClose={handleClose}>
          <Grid container direction="column" rowSpacing={1}>
            <Grid item className="display-large__primary-one">
              {t("App.createUser.title")}
            </Grid>
          </Grid>
        </DialogTitleContainer>

        <DialogContent
          sx={{ overflowX: "hidden" }} // Evita el scroll horizontal
        >
          <form id="form-permissions" onSubmit={handleSubmit(handleCreateUser)}>
            <Grid container item direction="column">
            <Grid item style={{ display: "flex", alignItems: "center" }}>
            <h3 className="heading__text-grey" style={{ marginRight: "8px" }}>
              {t("App.createUser.information")}
            </h3>
            <Tooltip
              title={
                <span style={{ textAlign: "left", fontSize: "11px" }}>
                <p>
                  {isMobile 
                    ? t("App.createUser.paragraph")
                    : isScreenSmall 
                      ? <span><p>{t("App.createUser.paragraphOne")}</p>
                        <p>{t("App.createUser.paragraphTwo")}</p></span>
                      : t("App.createUser.paragraph")
                  }
                </p>
              </span>
              }
              placement={isMobile ? "bottom" : "right-end"}
                      PopperProps={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [isMobile
                                ? 0  // No ajustar el desplazamiento horizontal en móviles
                                : isScreenSmall
                                ? 0  // Ajustar el desplazamiento horizontal en tabletas
                                : 0,  // No ajustar el desplazamiento horizontal en pantallas grandes
                              isMobile
                                ? -10  // Ajuste vertical en móviles
                                : isScreenSmall
                                ? -90  // Ajuste vertical en tabletas
                                : 0,  // Ajuste vertical en pantallas grandes
                                ], // Ajusta la distancia del tooltip respecto al icono
                            },
                          },
                          {
                            name: 'preventOverflow',
                            options: {
                              padding: isMobile ? 4 : 8, // Asegúrate de que no se salga de la pantalla
                            },
                          },
                        ],
                      }}
            >
              <InfoOutlinedIcon sx={{ fontSize: "22px", color: "#543AB4", cursor: "pointer" }} />
            </Tooltip>
          </Grid>

              <Grid item>
                <UserInfoComponent control={control} errors={errors} t={t} />
              </Grid>
              <Grid item>
                <Grid container direction="row">
                  <Grid item lg={1} md={2} sm={2} xs={12} paddingY={1} style={{ display: "flex", alignItems: "center" }} >
                    <h3 className="heading__text-grey">
                      {t("App.createUser.permit")}
                    </h3>
                    <Tooltip
                      title={<span style={{ textAlign: "left", fontSize: "12px" }}><p>{t("App.createUser.paragraphPermit")}</p></span>}
                      placement={isMobile ? "top" : "right-end"}
                      PopperProps={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [isMobile
                                ? 0  // No ajustar el desplazamiento horizontal en móviles
                                : isScreenSmall
                                ? 0  // Ajustar el desplazamiento horizontal en tabletas
                                : 0,  // No ajustar el desplazamiento horizontal en pantallas grandes
                              isMobile
                                ? -10  // Ajuste vertical en móviles
                                : isScreenSmall
                                ? -90  // Ajuste vertical en tabletas
                                : 0,  // Ajuste vertical en pantallas grandes
                                ], // Ajusta la distancia del tooltip respecto al icono
                            },
                          },
                          {
                            name: 'preventOverflow',
                            options: {
                              padding: isMobile ? 4 : 8, // Asegúrate de que no se salga de la pantalla
                            },
                          },
                        ],
                      }}
                    
                    >
                      <InfoOutlinedIcon sx={{ textAlign:"right", fontSize: "22px", color: "#543AB4", marginLeft: "12px" }} />{" "}
                    </Tooltip>
                  </Grid>
                  
                </Grid>
              </Grid>
              <br />
              <Grid
                item
                container
                className="permissionBox"
                width={"100%"}
                sx={{ overflowX: "hidden" }} // Evita el scroll horizontal
              >
                <PermissionsRegisters
                  permissionsFields={permissionsFields}
                  handleAddNewPermission={handleAddNewPermission}
                  handleRemovePermission={handleRemovePermission}
                  handleProductChange={handleProductChange}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  productsList={productsList}
                  errors={errors}
                  loadingProductsAndRoles={loadingProductsAndRoles}
                  isBusinessGroup={isBusinessGroup}
                  rolesMap={rolesMap}
                  companiesMap={companiesMap}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            direction={isDesktop ? "row" : "column-reverse"}
            className="col-end"
            columnGap={4}
            sx={{ padding: "1.5%" }}
          >
            <Grid item xs={12} sm={2} lg={1} className="full-width">
              <Button
                className="btn__filled__gradient full-width"
                onClick={handleClose}
              >
                {t("App.buttonLabels.cancel")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} lg={2.2} className="full-width">
              <Button
                className="btn__applyFilter"
                type="submit"
                form="form-permissions"
                startIcon={!loadingCreate ? <AddIcon /> : <></>}
              >
                {!loadingCreate ? (
                  t("App.createUser.title")
                ) : (
                  <CircularProgress size={21} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <ModalInfo
        title={modalTitle}
        responseData={createUserData}
        onClose={() => {
          handleClose();
          setOpenModalInfo(false);
        }}
        open={openModalInfo}
      />
    </>
  );
};

export default CreateUser;
