import { Button, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import {
  getCurrentCompany,
  getDataKeyFilters,
  setDataKeyFilters,
} from "../../parts/listUsers/listUsersSlice";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  ApplyFiltersProduct,
  GetMenuLevel1,
} from "../../services/admin/adminServices";
import { CODES } from "../../consts/codes";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../services/user/deleteAndReload/deleteAndReload";
import { GetGeographiesList } from "../../services/admin/geographies/geographiesService";

export const FiltersAdminProducts = (props) => {
  const [unfilteredList, setUnfilteredList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [companiesUnalterd, setCompaniesUnaltered] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [eanList, setEanList] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);
  const [enabledFields, setEnabledFields] = useState(false);
  const [loading, setLoading] = useState(true);
  const dataKeyFilters = useSelector(getDataKeyFilters);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  const infoCompanyFilter = useSelector(getCurrentCompany);

  /**
   * t para la traducción de los textos
   */
  const { t } = useTranslation();

  /**
   * Opciones para el tipo de empresa
   */
  const typeComps = [
    {
      label: t("App.listUsers.retail"),
      value: "trader",
    },
    {
      label: t("App.listUsers.supplier"),
      value: "provider",
    },
  ];

  /**
   * Opciones para la configuración del listado de los autocomplete
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        const geographiesService = await GetGeographiesList();

        setLoading(true);

        if (
          geographiesService.status === CODES.COD_RESPONSE_HTTP_OK &&
          (geographiesService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST ||
            geographiesService.data.responseCode === CODES.COD_RESPONSE_SUCCESS)
        ) {
          const responseMessage = geographiesService.data.responseMessage;

          let countries = responseMessage.map((country) => {
            return {
              countryName: country.countryName,
              country: country.country,
            };
          });

          setUnfilteredList(countries);
          setCountryList(countries);

          setValuesOfTheFields();
        }

        setLoading(false);
      } catch (error) {
        console.log(
          "============== Error FiltersAdminProducts.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
  }, []);

  /**
   * Función para desabilitar typeCompany cuando el campo pais no esta seleccionado
   */
  const disableType = () => !watch("country");

  /**
   * Función para setear los valores de los campos
   */
  const setValuesOfTheFields = async () => {
    // Se valida si la navegación viene desde la vista userDetails, para setear los filtros seleccionados previamente por el usuario
    if (dataKeyFilters.navigateUserDetalis) {
      // Obteniendo los valores de los filtros seleccionados previamente por el usuario
      const {
        country: { country, countryName },
        nameCompany,
        eanCompany,
        product,
        typeCompany,
      } = JSON.parse(localStorage.getItem("dataKeyFilters")).filterKeys;

      const dataFilter = {
        bussiness: {
          companyName: dataKeyFilters.nameCompany
            ? dataKeyFilters.nameCompany
            : nameCompany,
          eanCompany: dataKeyFilters.eanCompany
            ? dataKeyFilters.eanCompany
            : eanCompany,
        },
        ean: dataKeyFilters.eanCompany ? dataKeyFilters.eanCompany : eanCompany,
        country: dataKeyFilters.country ? dataKeyFilters.country : country,
        product: dataKeyFilters.product ? dataKeyFilters.product : product,
        typeCompany:
          dataKeyFilters.typeCompany?.value !== ""
            ? dataKeyFilters.typeCompany
            : typeCompany,
      };

      // Cargando filtros con los datos selecionados previamente por el usuario
      handleFilters(dataFilter);
      setValue("country", {
        countryName: dataKeyFilters.country.countryName
          ? dataKeyFilters.country.countryName
          : countryName,
        country: dataKeyFilters.country.country
          ? dataKeyFilters.country.country
          : country,
      });
      setValue("bussiness", {
        companyName: dataKeyFilters.nameCompany
          ? dataKeyFilters.nameCompany
          : nameCompany,
        eanCompany: dataKeyFilters.eanCompany
          ? dataKeyFilters.eanCompany
          : eanCompany,
      });
      setEnabledFields(true);
      setValue(
        "ean",
        dataKeyFilters.eanCompany ? dataKeyFilters.eanCompany : eanCompany
      );
      setValue("typeCompany", {
        label:
          dataKeyFilters.typeCompany?.label !== ""
            ? dataKeyFilters.typeCompany.label
            : typeCompany.label,
        value:
          dataKeyFilters.typeCompany?.value !== ""
            ? dataKeyFilters.typeCompany.value
            : typeCompany.value,
      });
      await getCompaniesList();
      getActiveProduct();
      setValue(
        "product",
        dataKeyFilters.product ? dataKeyFilters.product : product
      );
    } else {
      localStorage.removeItem("dataKeyFilters");
    }
  };

  /**
   * Función para obtener las empresas de acuerdo al tipo de empresa y país seleccionado
   */
  const getCompaniesList = async () => {
    let newCompanies = [];
    const country = getValues("country").country;
    if (getValues("typeCompany").value === "provider") {
      newCompanies = await fetchSuppliers(country);
    } else {
      newCompanies = await fetchTraders(country);
    }
    const eanList = newCompanies.map((item) => item.value);

    const companyList = newCompanies.map((item) => {
      return { companyName: item.label, eanCompany: item.value };
    });

    setEanList(eanList);
    setCompanyList(companyList);
    setCompaniesUnaltered(companyList);
  };

  /**
   *
   * Consulta los proveedores de un país específico
   *
   * @param {*} country país a consultar
   * @returns lista de proveedores por país consultado
   */
  const fetchSuppliers = async (country) => {
    try {
      const suppliersService = await GetSuppliersService({ country });
      const responseCode = suppliersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          return suppliersList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================= Error FiltersAdminProducts.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para consultar los comercios de un país específico
   * @param {*} country País seleccionado
   * @returns lista de comercios por país consultado
   */
  const fetchTraders = async (country) => {
    try {
      const tradersService = await GetTradersService({ country });
      const responseCode = tradersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = tradersService.data.responseMessage;

          let tradersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          return tradersList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================= Error FiltersAdminProducts.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para reinicar los campos de empresa, eanEmpresa y producto cuando se cambia el país
   *
   */
  const handleChangeCountry = async () => {
    setValue("bussiness", null);
    setValue("ean", null);
    setValue("product", null);
  };

  /**
   *
   * Función para colocar el valor correspondiente en el autocomplete de eanEmpresa de acuerdo a la empresa escogida
   *
   * @param {Object} values Valor seleccionado
   */
  const handleChangeCompany = (values) => {
    if (values) {
      const company = values;

      const filtered = companiesUnalterd.filter(
        (item) => item.companyName === company.companyName
      );

      const eanList = filtered.map((item) => item.eanCompany);

      setValue("ean", company.eanCompany);

      setEanList(eanList);
    }
  };

  /**
   *
   * Función para colocar el valor correspondiente en el autocomplete de empresa de acuerdo al eanEmpresa escogido
   *
   *  @param {string} values Valor seleccionado
   */
  const handleChangeEan = (values) => {
    if (values) {
      const ean = values;

      const filtered = companiesUnalterd.filter(
        (item) => item.eanCompany === ean
      );

      const companyList = filtered.map((item) => {
        return { companyName: item.companyName, eanCompany: item.eanCompany };
      });

      setValue("bussiness", {
        companyName: companyList[0].companyName,
        eanCompany: companyList[0].eanCompany,
      });
      setCompanyList(companyList);
    }
  };

  /**
   * Función para reiniciar los valores del campo de empresa cuando eanEmpresa está vacio y viceversa
   */
  const resetValuesWhenClear = () => {
    setValue("ean", null);
    setValue("bussiness", null);

    const companyList = companiesUnalterd.map((item) => {
      return { companyName: item.companyName, eanCompany: item.eanCompany };
    });

    const eanList = companyList.map((item) => item.eanCompany);

    setCompanyList(companyList);
    setEanList(eanList);
  };

  /**
   *
   * Función para filtrar los resultados a mostrar en la tabla, de acuerdo al país, empresa, eanEmpresa y/o producto
   *
   * @param {Object} data Datos de los filtros
   */
  const handleFilters = async (data) => {
    try {
      props.setFilterNotFound(false);
      props.setIsLoadingData(true);
      props.setData([]);

      let obj = {
        country: data.country.country,
        nameCompany: data.bussiness.companyName,
        eanCompany: data.ean,
        idMenuOption: data.product ? data.product.value : null,
      };

      const companiesService = await ApplyFiltersProduct(obj);
      obj.nameCompany = data.bussiness;
      props.setFiltersInfo({
        country: data.country.country,
        nameCompany: data.bussiness.companyName,
        eanCompany: data.ean,
        idMenuOption: data.product ? data.product.value : null,
        typeCompany: data.typeCompany.value,
      });
      props.setFilters(obj);

      //Setteando informacion en REDUX de los filtros
      const filterKeys = {
        country: {
          countryName: data.country.countryName,
          country: data.country.country,
        },
        nameCompany: data.bussiness.companyName,
        eanCompany: data.ean,
        product: data.product,
        typeCompany: {
          label: data.typeCompany.label,
          value: data.typeCompany.value,
        },
        navigateUserDetalis: false,
      };
      dispatch(setDataKeyFilters({ datakey: filterKeys }));
      // Guardando en localstorage los filtros seleccionados por el usuario
      // para que no se borren al recargar la página
      localStorage.setItem(
        "dataKeyFilters",
        JSON.stringify({
          filterKeys,
        })
      );

      if (
        companiesService.status === CODES.COD_RESPONSE_HTTP_OK &&
        companiesService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = companiesService.data.responseMessage;
        props.setDataStructure(
          responseMessage,
          data.typeCompany.value === "trader"
        );
        if (responseMessage.length == 0) {
          props.setFilterNotFound(true);
        }
      } else {
        props.setOpenErrorModal(true);
      }
      props.setIsLoadingData(false);
    } catch (error) {
      console.log(
        "============== Error FiltersAdminProducts.jsx function handleFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para cambiar el tipo de empresa
   * @param {*} values valor seleccionado
   */
  const handleChangeTypeCompany = async (values) => {
    setValue("bussiness", null);
    setValue("ean", null);
    setValue("product", null);
    setCompanyList([]);
    setEanList([]);
    if (values) {
      setEnabledFields(true);
      await getCompaniesList();
    } else {
      setEnabledFields(false);
    }
  };

  /**
   * Schema para el formulario de filtrar productos
   */
  const schema = yup.object().shape({
    bussiness: yup.object().shape({
      companyName: yup
        .string()
        .required(t("App.validationMessages.required"))
        .min(2),
      eanCompany: yup.string(),
    }),
    country: yup.object().shape({
      countryName: yup
        .string()
        .required(t("App.validationMessages.required"))
        .min(2),
      country: yup.string(),
    }),
  });

  /**
   * useForm para resolver el schema de validación
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Función oara obtener el listado de los productos activos
   */
  async function getActiveProduct() {
    try {
      if (watch("ean")) {
        const activeProductsService = await GetMenuLevel1();

        if (
          activeProductsService.status === CODES.COD_RESPONSE_HTTP_OK &&
          activeProductsService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const products = activeProductsService.data.responseMessage.map(
            (product) => {
              return {
                label: product.menuOptionName,
                value: product.idMenuOption,
                selected: false,
              };
            }
          );
          setActiveProducts(products);
        }
      }
    } catch (error) {
      console.log(
        "============== Error FiltersAdminProducts.jsx function getActiveProduct ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  }

  /**
   * Función para habilitar el botón de filtros
   */
  const disableApplyFiltersBtn = () => {
    if (
      watch("country") &&
      watch("bussiness") &&
      watch("ean") &&
      watch("typeCompany")
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Función para limpiar los filtros
   */
  const cleanFilters = () => {
    setValue("country", null);
    setValue("ean", null);
    setValue("bussiness", null);
    setValue("typeCompany", null);
    setValue("product", null);
    setCompanyList([]);
    setEnabledFields(false);
    setEanList([]);
    props.setFoundResults(false);
    props.setData([]);
    props.setFilterNotFound(false);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        xs={12}
        sm={8}
        lg={9}
        md={8.5}
        item
        className="side-line-contact__bottomAlign"
        sx={{ padding: "0" }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item justifyContent="center" alignItems="center">
          <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item className="filter_form-responsive">
                <Grid
                  container
                  rowSpacing={2}
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xl={2.3} lg={4} md={4} sm={6} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.country")}
                        </label>
                      </Grid>

                      <Grid item width="100%">
                        <Controller
                          name={"country"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                errors.country
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              options={countryList}
                              getOptionLabel={(option) => option.countryName}
                              isOptionEqualToValue={(option, value) =>
                                option.country == value.country
                              }
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.countryN")}
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {loading ? (
                                            <CircularProgress
                                              sx={{ color: "#4DCAFA" }}
                                              size={15}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeCountry();
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={2.3} lg={4} md={4} sm={6} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.typeCompany")}*
                        </label>
                      </Grid>

                      <Grid item width="100%">
                        <Controller
                          name={"typeCompany"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              noOptionsText={t("App.listUsers.noOptions")}
                              disabled={disableType()}
                              className={
                                errors.typeCompany
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              options={typeComps}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.typeCompany")}
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {loading ? (
                                            <CircularProgress
                                              sx={{ color: "#4DCAFA" }}
                                              size={15}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeTypeCompany(values);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={2.3} lg={4} md={4} sm={6} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      width="100%"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.companyo")}
                        </label>
                      </Grid>

                      <Grid item width="100%">
                        <Controller
                          name={"bussiness"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              filterOptions={filterOptions}
                              disabled={
                                !enabledFields ||
                                !watch("typeCompany") ||
                                !getValues("typeCompany")
                              }
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                errors.bussiness
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              options={companyList}
                              getOptionLabel={(option) => option.companyName}
                              isOptionEqualToValue={(option, value) =>
                                option.eanCompany === value.eanCompany
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.eanCompany}>
                                    {option.companyName}
                                  </li>
                                );
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.companyo")}
                                    {...params}
                                  />
                                );
                              }}
                              onInputChange={(event, value, reason) => {
                                if (reason === "clear") {
                                  resetValuesWhenClear();
                                }
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeCompany(values, reason);
                                getActiveProduct();
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={2.3} lg={6} md={3.8} sm={6} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.ean")}
                        </label>
                      </Grid>

                      <Grid item width="100%">
                        <Controller
                          name={"ean"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disabled={!enabledFields}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                errors.ean
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              filterOptions={(options) => {
                                if (value?.length >= 3) {
                                  const filt = options.filter((ean) => {
                                    if (ean.toString().includes(value)) {
                                      return ean;
                                    }
                                  });
                                  return filt;
                                } else {
                                  return eanList;
                                }
                              }}
                              options={eanList}
                              onInputChange={(event, value, reason) => {
                                if (reason === "clear") {
                                  resetValuesWhenClear();
                                }
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.ean")}
                                    {...params}
                                    onChange={onChange}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeEan(values);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={2.3} lg={6} md={3.8} sm={12} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.product")}
                        </label>
                      </Grid>

                      <Grid item width="100%">
                        <Controller
                          name={"product"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disabled={watch("ean") ? false : true}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                errors.product
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              options={activeProducts}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.product")}
                                    {...params}
                                    onChange={onChange}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Grid xs={12} lg={3} md={3.5} sm={4} item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="buttonfilt-container"
          gap={2}
        >
          <Grid item className="dimentions-btn__applyFilter__container">
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disableRipple
              disabled={disableApplyFiltersBtn()}
              className={
                disableApplyFiltersBtn()
                  ? "btn__applyFilter-disabled"
                  : "btn__applyFilter"
              }
              type="submit"
              form="hook-form"
            >
              {t("App.listUsers.applyFilters")}
            </Button>
          </Grid>

          <Grid item className="dimentions-btn__applyFilter__container">
            <Button
              className={"btn__deleteFilter dimentions-btn__cleanFilter"}
              onClick={cleanFilters}
            >
              {t("App.listUsers.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
