import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetSuppliersService } from "../../../services/user/deleteAndReload/deleteAndReload";
import moment from "moment";
import ModalInfo from "../Modals/modalInfo";

import { GetOCNumbers } from "../../../services/user/adminPurchaseOrder/PurcharseOrderProviderService";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterAdminPurchaseOrderCommerce = ({
  setIsLoading,
  setOpenErrorModal,
  userInfo,
  getListRequest,
  setHasFilter,
  setObjFilter,
  getRequestInitials,
}) => {
  /**
   * t de useTranslation para las traducciones
   */
  const { t } = useTranslation();

  /**
   * Schema para la validación del formulario de los filtros
   */
  const schema = yup.object().shape({
    country: yup
      .object()
      .shape({ countryName: yup.string(), country: yup.string() })
      .required(),
  });

  /**
   * useForm para resolver el schema de validación
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Use requestState
   */
  const [suppliers, setSuppliers] = useState([]);
  const [eanSuppliers, setEanSuppliers] = useState([]);
  const [error, setError] = useState(false);
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const [isLoadingEan, setIsLoadingEan] = useState(false);
  const [filterResponse, setFilterResponse] = useState("");
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [ocNumbers, setOcNumbers] = useState([]);

  /**
   * useSelector para obtener la información del usuario que se ha iniciado sesión
   */

  /**
   *
   */
  useEffect(() => {
    async function getSuppliers() {
      try {
        if (!userInfo) return;

        setIsLoadingSupplier(true);
        setIsLoadingEan(true);

        const country = userInfo?.company?.country;

        const suppliersService = await GetSuppliersService({ country });

        const responseCode = suppliersService.data.responseCode;

        if (
          suppliersService.status === CODES.COD_RESPONSE_HTTP_OK &&
          responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              companyName: item.companyName,
              eanCompany: item.eanCompany,
            };
          });

          let eanSuppliersList = responseMessage.map(
            (eanSupplier) => eanSupplier.eanCompany
          );

          const suppliersCompleteList =
            suppliersList.length > 0
              ? [
                  { companyName: t("App.downloadSalesForce.selectAll") },
                  ...suppliersList,
                ]
              : [];

          const eanCompleteList =
            eanSuppliersList.length > 0
              ? [t("App.downloadSalesForce.selectAll"), ...eanSuppliersList]
              : [];
          setSuppliers(suppliersCompleteList);
          setEanSuppliers(eanCompleteList);
        }

        setIsLoadingSupplier(false);
        setIsLoadingEan(false);
        await fetchOCNumbers([]);
      } catch (error) {
        setOpenErrorModal(true);
        console.log(
          "================= Error filterPurchaseOrderCommerce.jsx getSuppliers ==================="
        );
        console.log(error);
        console.log("====================================");
      }
    }

    getSuppliers();
  }, [userInfo]);

  /**
   * Función para obtener los números de OC por comercio
   */
  const fetchOCNumbers = async (commerce) => {
    try {
      const obj = {
        eanTrader: userInfo.company.eanCompany,
        lstEansProviders: commerce,
      };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetOCNumbers(obj);
      if (
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST &&
        status === CODES.COD_RESPONSE_SUCCESS
      ) {
        let oclist = responseMessage.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        oclist =
          oclist.length > 1
            ? [{ label: t("App.downloadSalesForce.selectAll") }, ...oclist]
            : oclist;
        setOcNumbers(oclist);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("=========== fetchOCNumbers ===========");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Formatear la fecha
   */
  const formatDate = (date) => {
    let prueba = new Date(date);
    return moment(prueba).format("YYYY-MM-DD");
  };

  /**
   *
   * Función para filtrar la data por país, comercio, ean comercio, número OC y/o fecha OC
   *
   * @param {Object} data
   */
  const handleFilters = async (data) => {
    try {
      setIsLoading(true);
      // verificar si se seleccionó 'Seleccionar todo' en el campo de proveedor o ean
      let selectAll =
        data.supplier?.length > 0
          ? data.supplier.find(
              (item) =>
                item.companyName === t("App.downloadSalesForce.selectAll")
            )
          : data.ean?.length > 0
          ? data.ean.find(
              (item) => item === t("App.downloadSalesForce.selectAll")
            )
          : null;

      const obj = {
        eanTrader: userInfo.company.eanCompany,
        country: userInfo.company.country,
        lstEansProviders: selectAll
          ? []
          : data.supplier?.length > 0
          ? data.supplier
              .filter(
                (commerce) =>
                  commerce.companyName !== t("App.downloadSalesForce.selectAll")
              )
              .map((commerce) => commerce.eanCompany)
          : data.ean?.length > 0
          ? data.ean.filter(
              (ean) => ean !== t("App.downloadSalesForce.selectAll")
            )
          : [],

        ocDate: data.ocDate ? formatDate(data.ocDate) : null,
        requestDate: data.requestDate ? formatDate(data.requestDate) : null,
        lstOcNumbers:
          data.ocNumber?.length > 0
            ? data.ocNumber.find(
                (item) => item.label === t("App.downloadSalesForce.selectAll")
              )
              ? []
              : data.ocNumber.map((item) => item.label)
            : null,
      };
      setHasFilter(true);
      setObjFilter(obj);
      await getListRequest(obj);
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todo' cuuando otra opción es deseleccionada
   *
   * @param {Object} object
   * @param {boolean} includeSelectAll
   */
  const handleDeleteSelectAllOption = (object, includeSelectAll) => {
    const { list, reason, type } = object;

    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = list.filter((option) => {
        let value = option.companyName || option.label || option;

        return value !== t("App.downloadSalesForce.selectAll");
      });

      setValue(type, optionsWithoutSelectAll);
    }
  };

  /**
   *
   * Función que se ejecuta cuando hay un cambio en el campo de proveedor para accionar las funciones:
   * handleSelectAllOption y handleChangeEanOrSupplier
   *
   * @param {Array} list
   * @param {String} reason
   * @param {Object} detail
   */
  const onSupplierChange = async (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const commerce of values) {
      if (commerce.companyName === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        setValue("ean", []);
        break;
      }
    }
    setValue("ocNumber", []);

    setValue("ocDate", null);

    let sizeCommerce = suppliers.filter(
      (commerce) =>
        commerce.companyName !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (commerce) =>
        commerce.companyName !== t("App.downloadSalesForce.selectAll")
    ).length;
    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeCommerce && reason === "selectOption")
    ) {
      setValue("supplier", suppliers);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === suppliers.length - 1) {
      setValue(
        "supplier",
        values.filter(
          (commerce) =>
            commerce.companyName !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("supplier", values);
    }
    //Si solo se selecciona un proveedor se coloca el ean en el campo de ean
    if (getValues("supplier") && getValues("supplier").length === 1) {
      setValue(
        "ean",
        eanSuppliers.filter(
          (item) => item === getValues("supplier")[0].eanCompany
        )
      );
    } else {
      setValue("ean", []);
    }

    if (detail) {
      const option = detail.option;
      // Si se deselecciona 'Seleccionar todo' se reinicia el valor del autocomplete
      if (
        reason === "removeOption" &&
        option.companyName === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("supplier", []);
      }
    }
    await getOCNumbersByCommerceOrEan();
  };

  /**
   *  Función para obtener los números de OC por comercio o ean
   */
  const getOCNumbersByCommerceOrEan = async () => {
    let selectAll = false;
    let values = [];
    if (getValues("supplier").length > 0) {
      values = getValues("supplier");
      selectAll = values.find(
        (item) => item.companyName === t("App.downloadSalesForce.selectAll")
      );
      values = values.filter(
        (company) =>
          company.companyName !== t("App.downloadSalesForce.selectAll")
      );
      values = values.map((commerce) => commerce.eanCompany);
    } else {
      values = getValues("ean");
      selectAll = values.find(
        (item) => item === t("App.downloadSalesForce.selectAll")
      );
      values = values.filter(
        (company) => company !== t("App.downloadSalesForce.selectAll")
      );
    }
    await fetchOCNumbers(selectAll ? [] : values);
  };
  /**
   *
   * Función que se ejecuta cuando hay un cambio en el campo de comercio para accionar las funciones:
   * handleSelectAllOption
   *
   * @param {Array} list
   * @param {String} reason
   * @param {Object} detail
   */
  const onOcNumberChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const ocNumber of values) {
      if (ocNumber.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeTrader = ocNumbers.filter(
      (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeTrader && reason === "selectOption")
    ) {
      setValue("ocNumber", ocNumbers);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === ocNumbers.length - 1) {
      setValue(
        "ocNumber",
        values.filter(
          (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("ocNumber", values);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.label === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("ocNumber", []);
      }
    }
  };

  /**
   *
   * Función para manejar los comportamientos relacionados con la opción 'Seleccionar todo'
   *
   * @param {Object} object
   */
  const handleSelectAllOption = (object) => {
    let includeSelectAll = false;

    const { list, reason, detail, type, ocNumberOrSupplierList } = object;

    const valuesByType = {
      ocNumber: ocNumbers,
      supplier: suppliers,
      ean: eanSuppliers,
    };

    // value puede ser el valor de proveedor, ean o número oc
    for (const value of list) {
      if (
        value.label === t("App.downloadSalesForce.selectAll") ||
        value.companyName === t("App.downloadSalesForce.selectAll") ||
        value === t("App.downloadSalesForce.selectAll")
      ) {
        includeSelectAll = true;
      }
    }

    let sizeEan = eanSuppliers.filter(
      (ean) => ean !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = list.filter(
      (commerce) =>
        commerce.companyName !== t("App.downloadSalesForce.selectAll")
    ).length;

    // Si se escoge 'Seleccionar todo' se colocan todos los valores en el autocomplete
    // de lo contrario se colocan los valores seleccionados
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeEan && reason === "selectOption")
    ) {
      setValue(type, valuesByType[type]);
    } else if (list.length === eanSuppliers.length - 1) {
      setValue(
        "supplier",
        list.filter(
          (commerce) => commerce !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue(type, list);
    }

    // Si se seleccionan todas las opciones disponibles, se selecciona automáticamente 'Seleccionar todo'
    if (
      !includeSelectAll &&
      list?.length === ocNumberOrSupplierList?.length - 1
    ) {
      setValue(type, valuesByType[type]);
    }

    handleDeleteSelectAllOption(object, includeSelectAll);

    if (detail) {
      const option = detail.option;

      // Si se deselecciona 'Seleccionar todo' se reinicia el valor del autocomplete
      if (
        reason === "removeOption" &&
        (option.label === t("App.downloadSalesForce.selectAll") ||
          option.companyName === t("App.downloadSalesForce.selectAll") ||
          option === t("App.downloadSalesForce.selectAll"))
      ) {
        setValue(type, []);
      }
    }
  };

  /**
   *
   * Función para manejar los cambios en los campos de ean y proveedor:
   * Cuando se selecciona un ean automáticamente se selecciona el comercio y viceversa
   * Cuando se selecciona más de un proveedor se vacía los campos de ean y número OC
   *
   * @param {Object} object
   */
  const handleChangeEanOrSupplier = (object) => {
    const { list, reason, type } = object;

    if (reason === "clear")
      setValue(type === "supplier" ? "ean" : "supplier", []);

    const isSelectAll =
      list[0]?.companyName === t("App.downloadSalesForce.selectAll") ||
      list[0] === t("App.downloadSalesForce.selectAll");

    if (list?.length > 0 && !isSelectAll) {
      const eanOrSupplierSelected = list[0]?.eanCompany || list[0];

      const eanOrSupplier = suppliers.filter(
        (supplier) => supplier?.eanCompany === eanOrSupplierSelected
      );

      let eanValue = list.length === 1 ? [eanOrSupplier[0]?.eanCompany] : [];
      let supplierValue = list.length === 1 ? [eanOrSupplier[0]] : [];

      type === "ean"
        ? setValue("supplier", supplierValue)
        : setValue("ean", eanValue);
    } else {
      type === "ean" ? setValue("supplier", []) : setValue("ean", []);
    }
  };

  /**
   * Función para limpiar los campos del formulario
   */
  const handleCleanFilters = () => {
    reset();
    getRequestInitials();
  };

  /**
   * Función para actualizar el estado de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect para actualizar el estado de isDesktop basado en el tamaño de la ventana del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        xl={9}
        lg={9}
        md={9}
        sm={8}
        xs={12}
        item
        className="side-line-contact__bottomAlign"
        rowSpacing={2}
      >
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
          >
            <Grid
              container
              direction="row"
              rowSpacing={2}
              width="100%"
              paddingBottom={2}
              spacing={2}
            >
              <Grid item lg={5.85} md={6} sm={12} xs={12} width={"100%"}>
                <Controller
                  name="supplier"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={!isLoadingSupplier && watch("ean")?.length > 1}
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={errors.supplier ? "filters__error" : "filters"}
                      multiple
                      isOptionEqualToValue={(option, value) =>
                        option?.eanCompany === value?.eanCompany
                      }
                      getOptionLabel={(option) => option?.companyName}
                      id="checkboxes-tags-demo"
                      options={suppliers}
                      value={value || []}
                      disableCloseOnSelect
                      limitTags={3}
                      onChange={onSupplierChange}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.eanCompany}>
                          <Checkbox
                            icon={icon}
                            className="autoComplete__checkbox"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.companyName}
                        </li>
                      )}
                      renderTags={(value) => {
                        const numTags = value.length;
                        const limitTags = isDesktop ? 2 : 1;

                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                sx={{
                                  width: "30%",
                                }}
                                key={index}
                                size="small"
                                label={option?.companyName}
                              />
                            ))}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label={t("App.deleteAndReload.table.supplier")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingSupplier ? (
                                  <CircularProgress
                                    sx={{ color: "#4DCAFA" }}
                                    size={15}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={5.85} md={6} sm={12} xs={12} width={"100%"}>
                <Controller
                  name="ean"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={!isLoadingEan && watch("supplier")?.length > 1}
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={errors.ean ? "filters__error" : "filters"}
                      isOptionEqualToValue={(option, value) => option === value}
                      id="checkboxes-tags-demo"
                      options={eanSuppliers}
                      value={value || []}
                      multiple
                      disableCloseOnSelect
                      onChange={async (e, values, reason) => {
                        const obj = { list: values, reason, type: "ean" };

                        handleSelectAllOption(obj);
                        handleChangeEanOrSupplier(obj);
                        await getOCNumbersByCommerceOrEan();
                        // onChange(values);
                      }}
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            className="autoComplete__checkbox"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderTags={(value) => {
                        const numTags = value.length;
                        const limitTags = 2;

                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                sx={{
                                  width: "30%",
                                }}
                                key={index}
                                size="small"
                                label={option}
                              />
                            ))}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label={t(
                            "App.adminPurchaseOrderCommerce.filter.eanSupplier"
                          )}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingEan ? (
                                  <CircularProgress
                                    sx={{ color: "#4DCAFA" }}
                                    size={15}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              rowSpacing={2}
              spacing={2}
              width="100%"
            >
              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  defaultValue=""
                  name="ocNumber"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={errors.ocNumber ? "filters__error" : "filters"}
                      multiple
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      id="checkboxes-tags-demo"
                      options={ocNumbers}
                      value={value || []}
                      disableCloseOnSelect
                      limitTags={3}
                      onChange={onOcNumberChange}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            className="autoComplete__checkbox"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderTags={(value) => {
                        const numTags = value.length;
                        const limitTags = 2;

                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                sx={{
                                  width: "25%",
                                }}
                                key={index}
                                size="small"
                                label={option.label}
                              />
                            ))}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label={t("App.adminPurchaseOrder.table.ocNumber")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  defaultValue=""
                  control={control}
                  name="ocDate"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={errors.ocDate ? "filters__error" : "filters"}
                        inputFormat="YYYY-MM-DD"
                        label={t("App.adminPurchaseOrder.table.ocDate")}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={watch("ocDate") ? watch("ocDate") : null}
                        onChange={onChange}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>

              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  defaultValue=""
                  control={control}
                  name="requestDate"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={
                          errors.requestDate ? "filters__error" : "filters"
                        }
                        inputFormat="YYYY-MM-DD"
                        label={t("App.requestsPurchaseOrder.table.requestDate")}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={
                          watch("requestDate") ? watch("requestDate") : null
                        }
                        onChange={onChange}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} width={"100%"}>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disableRipple
              className={"btn__applyFilter-reload"}
              type="submit"
              form="hook-form"
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>

          <Grid item xl={5} lg={6} md={12} sm={12} xs={12} width="100%">
            <Button
              onClick={handleCleanFilters}
              disableRipple
              className="btn__deleteFilter-reload"
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {error && (
        <ModalInfo
          responseData={filterResponse}
          open={error}
          onClose={() => setError(false)}
        />
      )}
    </Grid>
  );
};

export default FilterAdminPurchaseOrderCommerce;
